import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { completeFilter } from '../util/filters';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import cities from '../data/dataSheet.json';
import { Helmet } from 'react-helmet/es/Helmet';
import { putToolbarStateAction } from '../actions/toolbarState/putToolbarStateAction';
import { MOBILE_WIDTH } from '../constants/widths';
import { addToVisited, removeFromVisited } from '../repository/user/visited';
import { addToWantToGo, removeFromWantToGo } from '../repository/user/wantToGo';
import { createNewTrip, updateSingleTrip } from '../repository/trip/trip';
import IntelligentSearch from '../components/Cities/IntelligentSearch';
import { putSearchValue } from '../actions/searchValue/putSearchValue';
import { addSelectedAction } from '../actions/selected/addSelectedAction';
import { putSelectedCountriesAction } from '../actions/selectedCountries/putSelectedCountriesAction';
import { putSelectedContinentsAction } from '../actions/selectedContinents/putSelectedContinentsAction';
import { putClimateAction } from '../actions/climate/putClimateAction';
import { removeSelectedAction } from '../actions/selected/removeSelectedAction';
import { toTitleCase } from '../util/textFormatting';
import countries from '../data/countries.json';
import continents from '../data/continents.json';
import { putSelectedAction } from '../actions/selected/putSelectedAction';
import { Card, CardActions } from '@material-ui/core';
import { ColouredLogo, colourScheme } from '../util/colourSchemes';
import CardMedia from '@material-ui/core/CardMedia';
import { getCoverImage } from '../util/images';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { event } from '../util/tracking';
import OpenInAppIcon from 'mdi-react/OpenInAppIcon';
import { listTrips } from '../graphql/queries';
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Auth from '@aws-amplify/auth';
import Tooltip from '@material-ui/core/Tooltip';
import GoogleButton from 'react-google-button';
import { isValidCity } from '../util/cityData';

const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    transition: 'opacity 700ms linear',
    transitionDelay: '100ms'
  }
});

/**
 * Filter out cities that match intelligent search, then only return cities
 * that contain the filtered cities
 *
 * @param trips {array}
 * @param cities {object}
 * @param climate {object}
 * @param selected {array}
 * @param countries {array}
 * @param continents {array}
 * @returns {array}
 */
const filterTrips = (trips, cities, climate, selected, countries, continents) => {
  const filteredCities = completeFilter(cities, climate, selected, countries, continents);
  const citySet = new Set(Object.keys(filteredCities));
  return trips.filter(({ tripObject: trip }) => {
    for (const city of trip.cities) {
      if (citySet.has(city)) {
        return true;
      }
    }
    return false;
  });
};

const generateNameFromTripContent = (citySet) => {
  let countrySet = new Set([]);
  citySet.forEach((cityName) => {
    if (isValidCity(cityName)) {
      countrySet.add(cities[cityName].country);
    }
  });
  if (countrySet.size > 3) {
    let continentSet = new Set([]);
    countrySet.forEach((countryCode) => {
      continentSet.add(continents[countries[countryCode.toUpperCase()].continent]);
    });
    return Array.from(continentSet).join(', ');
  }
  return Array.from(countrySet)
    .map((countryCode) => countries[countryCode.toUpperCase()].name)
    .join(', ');
};

/**
 * This class acts as a grid to contain all the cities and display them to users
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
function TripSearch(props) {
  const {
    selected,
    trips,
    visited,
    searchValue,
    wantToGo,
    climate,
    loading,
    history,
    globalTheme,
    updateSearchValue,
    toolbarState,
    putToolbarState,
    user,
    search,
    updateSelectedAddAction,
    updateSelectedRemoveAction,
    updateContinents,
    updateCountries,
    selectedContinents,
    selectedCountries,
    updateClimateAction,
    createTrip,
    updateSelectedAction,
    classes
  } = props;

  const handleLogin = async (provider) => {
    event(
      {
        category: 'Authentication',
        action: 'Clicked Feature Login',
        label: 'Google'
      },
      'login',
      { method: 'Google' }
    );
    await Auth.federatedSignIn({ provider });
  };

  const [userTrips, setUserTrips] = useState([]);
  const getAllTrips = async () => {
    let allTrips = [];
    let nextToken = undefined;
    while (nextToken !== null) {
      console.log(nextToken);
      const existingTrips = await GraphQLAPI.graphql(
        graphqlOperation(listTrips, { nextToken: nextToken === undefined ? null : nextToken })
      );
      console.log(existingTrips);
      allTrips = [...allTrips, ...existingTrips.data.listTrips.items];
      nextToken = existingTrips.data.listTrips.nextToken;
    }
    console.log(allTrips);
    setUserTrips(allTrips);
  };

  // Track window width changes
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
  useEffect(() => {
    if (user) {
      getAllTrips();
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const memoizedResults = useMemo(() => {
    let filteredItems = userTrips;
    filteredItems.forEach((trip) => {
      if (typeof trip.tripObject === 'string') {
        trip.tripObject = JSON.parse(trip.tripObject);
      }
    });
    filteredItems = filteredItems.filter((trip) => trip.tripObject.cities.length > 0);
    filteredItems = filterTrips(filteredItems, cities, climate, selected, selectedCountries, selectedContinents);

    filteredItems = filteredItems.sort(({ tripObject: a }, { tripObject: b }) => {
      const planSorting =
        (b.plans !== undefined ? Object.keys(b.plans).length : 0) -
        (a.plans !== undefined ? Object.keys(a.plans).length : 0);
      const citySorting = b.cities.length - a.cities.length;
      return (b.plans || a.plans) && planSorting !== 0 ? planSorting : citySorting;
    });

    filteredItems = filteredItems.reverse();
    const comparables = filteredItems.map(({ name }) => name);
    filteredItems = filteredItems.filter(({ tripObject, name }, index) => !comparables.includes(name, index + 1));
    filteredItems = filteredItems.filter((item) => {
      item.name = generateNameFromTripContent(item.tripObject.cities);
      return item;
    });
    return filteredItems.reverse();
  }, [userTrips, climate, selected, selectedCountries, selectedContinents]);

  const { sidebarOpen } = toolbarState;
  if (!user)
    return (
      <Grid container justify="center" alignItems="center" direction={'column'} style={{ marginTop: '72px' }}>
        <ColouredLogo size={'320px'} theme={globalTheme} alt="travel-atlas logo" />
        <GoogleButton
          style={{
            boxShadow:
              '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 4px 7px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
          }}
          type={globalTheme.colourScheme === 'dark' ? 'dark' : 'light'}
          onClick={() => handleLogin('Google')}
        />
        <Typography
          style={{
            padding: '12px',
            textAlign: 'center',
            color: colourScheme[globalTheme.colourScheme].primaryText,
            marginBottom: '24px'
          }}
        >
          You can only use this feature while logged-in.
        </Typography>
      </Grid>
    );

  if (loading || userTrips.length === 0)
    return (
      <Grid container style={{ marginTop: '72px' }} justify={`center`} align={`center`}>
        <CircularProgress size={72} />
      </Grid>
    );

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://travel-atlas.com/" />
        <meta charSet="utf-8" />
        <title>
          User Trips {selected.length ? ' | ' + toTitleCase(selected.join(', ')) : ''}
          {selectedCountries.length
            ? ' | ' + toTitleCase(selectedCountries.map((code) => countries[code.toUpperCase()].name).join(', '))
            : ''}
          {selectedContinents.length
            ? ' | ' + toTitleCase(selectedContinents.map((code) => continents[code.toUpperCase()]).join(', '))
            : ''}{' '}
          | Travel-Atlas
        </title>
        <meta
          name="description"
          content={`See and copy existing trips from other users! Get inspiration. Filter by your interests`}
        />
      </Helmet>
      <Container>
        {!(width < MOBILE_WIDTH && sidebarOpen) ? (
          <div style={{ marginTop: '24px' }}>
            <IntelligentSearch
              putToolbarState={putToolbarState}
              toolbarState={toolbarState}
              searchValue={searchValue}
              updateSearchValue={updateSearchValue}
              history={history}
              visited={visited}
              wantToGo={wantToGo}
              trips={trips}
              searches={search}
              selectedAttributes={selected}
              updateSelectedAddAction={updateSelectedAddAction}
              updateSelectedRemoveAction={updateSelectedRemoveAction}
              selectedCountries={selectedCountries}
              selectedContinents={selectedContinents}
              updateCountries={updateCountries}
              updateContinents={updateContinents}
              selectedClimate={climate}
              updateClimate={updateClimateAction}
              globalTheme={globalTheme}
              updateSelectedAction={updateSelectedAction}
              hideMap={true}
            />
          </div>
        ) : null}
        <Divider
          style={{
            marginTop: '12px',
            marginBottom: '12px',
            background: colourScheme[globalTheme.colourScheme].primaryText
          }}
        />
        <Grid spacing={3} container justify={'center'} alignItems={'center'} style={{ marginBottom: '12px' }}>
          {memoizedResults.map((trip, index) => (
            <Grid item key={index} xs={12} md={6} lg={3}>
              <Card style={{ padding: '12px', background: colourScheme[globalTheme.colourScheme].cardColour }}>
                <Grid spacing={1} container justify={'center'} alignItems={'center'}>
                  {memoizedResults[index].tripObject.cities
                    .filter((i, index) => index < 12)
                    .map((city, cityIndex) => (
                      <Grid item key={trip.name + index + city + cityIndex} xs={4}>
                        <Tooltip title={toTitleCase(city)} aria-label={toTitleCase(city)}>
                          <CardMedia
                            className={classes.media}
                            image={getCoverImage(city, 240)}
                            alt={city}
                            style={{ borderRadius: '4px' }}
                          />
                        </Tooltip>
                      </Grid>
                    ))}
                </Grid>
                <CardContent>
                  <Typography
                    variant={'h6'}
                    style={{
                      color: colourScheme[globalTheme.colourScheme].primaryText,
                      textAlign: 'center'
                    }}
                  >
                    {trip.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid container justify={'center'} alignItems={'center'}>
                    <Button
                      color={'primary'}
                      onClick={async () => {
                        event(undefined, 'create_trip', { page: 'trip_search' });
                        await createTrip(user, trips, trip.name, trip.tripObject);
                        history.push('saved/trips');
                      }}
                    >
                      <OpenInAppIcon />
                      Copy This Trip
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  visited: state.visited,
  wantToGo: state.wantToGo,
  trips: state.trips,
  climate: state.climate,
  selected: state.selected,
  globalTheme: state.theme,
  searchValue: state.searchValue,
  toolbarState: state.toolbarState,
  search: state.search,
  user: state.user,
  selectedCountries: state.selectedCountries,
  selectedContinents: state.selectedContinents
});

const mapDispatchToProps = (dispatch) => ({
  updateVisitedAddAction: (user, set, item) => addToVisited(dispatch, user, set, item),
  updateVisitedRemoveAction: (user, set, item) => removeFromVisited(dispatch, user, set, item),
  updateWantToGoAddAction: (user, set, item) => addToWantToGo(dispatch, user, set, item),
  updateWantToGoRemoveAction: (user, set, item) => removeFromWantToGo(dispatch, user, set, item),
  putToolbarState: (value) => dispatch(putToolbarStateAction(value)),
  changeTrip: (user, trip, indexOfTrip) => updateSingleTrip(dispatch, user, trip, indexOfTrip),
  updateSearchValue: (value) => dispatch(putSearchValue(value)),
  updateSelectedAddAction: (toAdd) => dispatch(addSelectedAction(toAdd)),
  updateSelectedRemoveAction: (toRemove) => dispatch(removeSelectedAction(toRemove)),
  updateCountries: (newValue) => dispatch(putSelectedCountriesAction(newValue)),
  updateContinents: (newValue) => dispatch(putSelectedContinentsAction(newValue)),
  updateClimateAction: (value) => dispatch(putClimateAction(value)),
  updateSelectedAction: (value) => dispatch(putSelectedAction(value)),
  createTrip: (user, trips, tripName, tripObject) => createNewTrip(dispatch, user, trips, tripName, tripObject)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })((props) => <TripSearch {...props} />)));
