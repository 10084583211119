/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTrip = /* GraphQL */ `
  query GetTrip($id: ID!) {
    getTrip(id: $id) {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTrips = /* GraphQL */ `
  query ListTrips($filter: ModelTripFilterInput, $limit: Int, $nextToken: String) {
    listTrips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTripEditor = /* GraphQL */ `
  query GetTripEditor($id: ID!) {
    getTripEditor(id: $id) {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTripEditors = /* GraphQL */ `
  query ListTripEditors($filter: ModelTripEditorFilterInput, $limit: Int, $nextToken: String) {
    listTripEditors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trip {
          id
          name
          tripObject
          createdAt
          updatedAt
        }
        editor {
          id
          username
          visited
          wantToGo
          search
          email
          emailConfirmed
          profilePicture
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks($filter: ModelFeedbackFilterInput, $limit: Int, $nextToken: String) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        status
        description
        reporter {
          id
          username
          visited
          wantToGo
          search
          email
          emailConfirmed
          profilePicture
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
