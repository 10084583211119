import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { onCreateTripEditor, onEditTrip } from '../../graphql/subscriptions';
import { getUser } from '../../graphql/queries';
import { getTripForTripEditorId, resolveUserTrips, updateLocalTripsState } from './trip';

export const setupTripSubscriptionsGraph = async (dispatch, user) => {
  try {
    // Handle new trip creation
    GraphQLAPI.graphql(graphqlOperation(onCreateTripEditor)).subscribe({
      next: async (sub) => {
        // Only add new trips if the tripeditor for the new trip is our user
        if (sub.value.data.onCreateTripEditor.editor.id === user.id) {
          // We need to fetch the new user DB relations
          const existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: user.id }));
          user = existingUser.data.getUser;
          if (user.trips && user.trips.items) {
            const resolvedTrips = await Promise.all(
              user.trips.items.map(
                await (async (item) => {
                  return await getTripForTripEditorId(item.id);
                })
              )
            );
            // Subscribe to changes in the new trip
            GraphQLAPI.graphql(graphqlOperation(onEditTrip, { id: user.id })).subscribe({
              next: async (sub) => {
                const index = resolvedTrips.findIndex((element) => element.id === sub.value.data.onEditTrip.id);
                resolvedTrips[index] = sub.value.data.onEditTrip;
                await updateLocalTripsState(dispatch, resolvedTrips);
              }
            });
            await updateLocalTripsState(dispatch, resolvedTrips);
          }
        }
      }
    });

    // Sub to existing trips
    const resolvedTrips = await resolveUserTrips(user);
    await updateLocalTripsState(dispatch, resolvedTrips);
    for (const [index, trip] of resolvedTrips.entries()) {
      GraphQLAPI.graphql(graphqlOperation(onEditTrip, { id: trip.id })).subscribe({
        next: async (data) => {
          resolvedTrips[index] = data.value.data.onEditTrip;
          await updateLocalTripsState(dispatch, resolvedTrips);
        }
      });
    }
  } catch (error) {
    console.error('Failed to setup trip subscriptions', error);
  }
};
