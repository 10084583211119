import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import * as Sentry from '@sentry/react';

function configureStore() {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
  const initialState = {
    wantToGo: [],
    attributes: {},
    visited: [],
    selected: [],
    search: {},
    trips: [],
    user: null,
    climate: {
      weather: [],
      months: []
    },
    theme: {
      colourScheme: 'light',
      hd: false
    },
    toolbarState: {
      searchDrawer: false
    },
    searchValue: '',
    privacy: false,
    selectedCountries: [],
    selectedContinents: []
  };

  const persistConfig = {
    key: 'root',
    storage,
    debug: process.env.NODE_ENV === 'development'
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const logger = createLogger({});
  return createStore(
    persistedReducer,
    initialState,
    process.env.NODE_ENV === 'development'
      ? applyMiddleware(thunk, logger)
      : compose(applyMiddleware(thunk), sentryReduxEnhancer)
  );
}

export const store = configureStore();
export const persistor = persistStore(store);
