/**
 * This function adds to a string array set
 * @param {array} set
 * @param {string} item the name of the destination to be added
 * @returns {Object}
 * @public
 */
export const addToSet = (set, item) => {
  if (!set.includes(item)) {
    set.push(item);
  }
  return set;
};

/**
 * This function removes from a string array set
 *
 * @param {array} set
 * @param {string} item the name of the destination to be removed
 * @returns {Object}
 * @public
 */
export const removeFromSet = (set, item) => {
  return set.filter((city) => city !== item);
};
