/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:09bf982a-7f41-4c61-8739-48cf64a6e642",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_iYQ10SBBS",
    "aws_user_pools_web_client_id": "725u2tkbqpa5rathq9b2ho4h4o",
    "oauth": {
        "domain": "travel-atlas-master.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://travel-atlas.com/,https://test-amplify.d2gc0o88uvhlzz.amplifyapp.com/,https://master.d2gc0o88uvhlzz.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://travel-atlas.com/,https://test-amplify.d2gc0o88uvhlzz.amplifyapp.com/,https://master.d2gc0o88uvhlzz.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "travelatlas-20200625143617-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d1u5r5wh94s62a.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://j23ozrvfgnhodgnjwxshe4uway.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
