const wantToGoReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_WANT_TO_GO':
      return [...state, action.payload];
    case 'REMOVE_WANT_TO_GO':
      return state.filter((val) => val !== action.payload);
    case 'UPDATE_WANT_TO_GO':
      return [...action.payload];
    default:
      return state;
  }
};

export default wantToGoReducer;
