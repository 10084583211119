import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { batch } from 'react-redux';
import { getUser } from '../../graphql/queries';
import { updateVisitedAction } from '../../actions/visited/updateVisitedAction';
import { updateSearchAction } from '../../actions/search/updateSearchAction';
import { updateWantToGoAction } from '../../actions/wantToGo/updateWantToGoAction';
import { updateUserAction } from '../../actions/user/updateUserAction';
import { updateLocalTripsState } from '../trip/trip';
import { updateUser } from '../../graphql/mutations';

/**
 * Turns a normal user into serialized strings for some fields
 *
 * @param {object} user a basic json user
 * @returns {*}
 */
export const serializeUser = (user) => {
  if (!Array.isArray(user.visited) || !Array.isArray(user.wantToGo) || !user.search instanceof Object) {
    console.error('User data is corrupted', user);
  }

  const serializedData = {
    visited: JSON.stringify(user.visited),
    wantToGo: JSON.stringify(user.wantToGo),
    search: JSON.stringify(user.search)
  };
  Object.assign(user, serializedData);
  return user;
};

/**
 * Takes the serialized user data and turns it into normal JSON
 *
 * @param {object} graphQlUser The user object that graphql returns from queries
 * @returns {*}
 */
export const deSerializeUser = (graphQlUser) => {
  if (!graphQlUser) {
    console.error('The fetched user data from graphql was undefined.', graphQlUser);
  }
  const deSerializedData = {
    visited: JSON.parse(graphQlUser.visited),
    wantToGo: JSON.parse(graphQlUser.wantToGo),
    search: JSON.parse(graphQlUser.search)
  };
  const user = graphQlUser;
  Object.assign(user, deSerializedData);
  return user;
};

/**
 * Takes a user object and updates the relevant local states in one batch update
 *
 * @param dispatch
 * @param user
 * @returns {Promise<*>}
 */
export const pullRemoteUserState = async (dispatch, user, callerFunction) => {
  try {
    if (!user || !user.id) {
      console.error('Tried to full remote user state for non-existent user', callerFunction);
    }
    const existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: user.id }));
    user = deSerializeUser(existingUser.data.getUser);
    await updateLocalUserState(dispatch, user);
  } catch (error) {
    console.error('Failed to pull remote state for user', user);
  }
  return user;
};

/**
 * Takes a user object and updates the relevant local states in one batch update
 *
 * @param {function} dispatch - the function used to dispatch state changes to the redux store
 * @param {object} user - the local user state
 * @param {array} visited - the local visited state
 * @param {array} wantToGo - the local wantToGo state
 * @param {object} search - the local search state
 * @param {object} trips - the local trips state
 * @returns {Promise<*>}
 */
export const updateLocalUserState = async (
  dispatch,
  user,
  visited = null,
  wantToGo = null,
  search = null,
  trips = null
) => {
  try {
    // In this case we update only parts of the state we received data for
    batch(() => {
      if (visited !== null) {
        dispatch(updateVisitedAction(visited));
      }
      if (search !== null) {
        dispatch(updateSearchAction(search));
      }
      if (wantToGo !== null) {
        dispatch(updateWantToGoAction(wantToGo));
      }
      if (trips !== null) {
        updateLocalTripsState(dispatch, trips);
      }
      dispatch(updateUserAction(user));
    });
  } catch (error) {
    console.error('Failed to update local state for user', user);
  }
  return user;
};

/**
 * Gets the remote user data and sets it to the local state
 *
 * @param dispatch
 * @param user
 * @returns {Promise<*>}
 */
export const updateRemoteUserState = async (dispatch, user) => {
  if (user === null) {
    return null;
  }
  try {
    user = serializeUser(
      (({ id, username, visited, wantToGo, search, _version }) => ({
        id,
        username,
        visited,
        wantToGo,
        search,
        _version
      }))(user)
    );
    const updatedUser = await GraphQLAPI.graphql(graphqlOperation(updateUser, { input: user }));
    user = deSerializeUser(updatedUser.data.updateUser);
    user = updateLocalUserState(dispatch, user, user.visited, user.wantToGo, user.search);
  } catch (error) {
    console.error('Failed to fetch remotely and set locally latest user data', error, user);
  }
  return user;
};
