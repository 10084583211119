import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { colourScheme } from '../util/colourSchemes';
import Grid from '@material-ui/core/Grid';
import cities from '../data/dataSheet.json';
import { event } from '../util/tracking';
import Auth from '@aws-amplify/auth';
import { Parallax } from 'react-parallax';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LazyCityCard from '../components/LazyComponents/LazyCityCard';
import { addToVisited, removeFromVisited } from '../repository/user/visited';
import { addToWantToGo, removeFromWantToGo } from '../repository/user/wantToGo';
import { putToolbarStateAction } from '../actions/toolbarState/putToolbarStateAction';
import { createNewTrip, updateSingleTrip } from '../repository/trip/trip';
import { putSelectedCountriesAction } from '../actions/selectedCountries/putSelectedCountriesAction';
import { putSelectedContinentsAction } from '../actions/selectedContinents/putSelectedContinentsAction';
import { putClimateAction } from '../actions/climate/putClimateAction';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { ListItem } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { SocialIcon } from 'react-social-icons';
import { withStyles } from '@material-ui/core/styles';
import { putTheme } from '../actions/theme/putTheme';
import Profile from './Profile';
import TripPrompt from '../components/Landing/TripPrompt';

const styles = (theme) => ({
  socialIcon: {
    height: '50px',
    width: '50px',
    '&:hover': {
      borderRadius: '100px',
      backgroundClip: 'content-box',
      backgroundColor: '#B0BEC5'
    }
  },
  insideStyles: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  },
  bigAvatar: {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#2196f3',
    width: 60,
    height: 60,
    marginRight: '12px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    transition: 'opacity 700ms linear',
    transitionDelay: '100ms'
  }
});

const handleLogin = async (provider) => {
  event(undefined, 'view_feature', { feature: 'login', page: 'landing' });
  event(
    {
      category: 'Authentication',
      action: 'Clicked Feature Login',
      label: 'Google'
    },
    'login',
    { method: provider, page: 'landing' }
  );
  await Auth.federatedSignIn({ provider });
};

function Landing(props) {
  const {
    selected,
    visited,
    wantToGo,
    climate,
    history,
    updateVisitedAddAction,
    updateVisitedRemoveAction,
    updateWantToGoAddAction,
    updateWantToGoRemoveAction,
    globalTheme,
    user,
    trips,
    changeTrip,
    createTrip,
    classes,
    putThemeAction
  } = props;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://travel-atlas.com/give-feedback" />
        <meta charSet="utf-8" />
        <title>Plan a Trip - Explore the World | Travel-Atlas</title>
        <meta
          name="description"
          content={`Plan your trips based on your interests and the reviews and example trips of people like you.`}
        />
      </Helmet>
      <Parallax
        bgImage={`https://travel-atlas.com/image/${globalTheme.hd ? 'original' : '1080'}/${
          colourScheme[globalTheme.colourScheme].landingImage
        }.jpg`}
        strength={400}
      >
        <TripPrompt
          globalTheme={globalTheme}
          user={user}
          trips={trips}
          createTrip={createTrip}
          forwardOnCreate={false}
          history={history}
        />
      </Parallax>
      <Container style={{ textAlign: 'center', padding: '12px' }}>
        <Profile history={history} component={true} />
        <Paper
          style={{
            textAlign: 'center',
            padding: '12px',
            background: colourScheme[globalTheme.colourScheme].cardColour
          }}
        >
          <Typography
            style={{
              color: colourScheme[globalTheme.colourScheme].primaryText,
              fontFamily: 'Oswald'
            }}
            variant="h4"
          >
            EVERYTHING YOU NEED TO PLAN YOUR TRIP IN ONE PLACE
          </Typography>
          <Divider
            style={{
              background: colourScheme[globalTheme.colourScheme].primaryText
            }}
          />
          <Grid container justify="center" alignItems="center">
            <List>
              <ListItem
                button
                onClick={() => {
                  event(undefined, 'view_feature', { feature: 'trips_with_friends', page: 'landing' });
                  history.push('/saved/trips');
                }}
              >
                <ListItemIcon>
                  <Avatar
                    className={classes.bigAvatar}
                    alt="Trips"
                    src={'https://travel-atlas.com/image/icons/world.svg'}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                    >
                      Create a Personalized Trip and Plan it with Friends
                    </Typography>
                  }
                />
                <ListItemIcon>
                  <Button variant="outlined" style={{ color: 'green' }}>
                    GO
                  </Button>
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  event(undefined, 'view_feature', { feature: 'search', page: 'landing' });
                  history.push('/cities');
                }}
              >
                <ListItemIcon>
                  <Avatar
                    className={classes.bigAvatar}
                    alt="Trips"
                    src={'https://travel-atlas.com/image/icons/beaches.svg'}
                  />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                    >
                      Search for destinations based on your interests
                    </Typography>
                  }
                />
                <ListItemIcon>
                  <Button variant="outlined" style={{ color: 'green' }}>
                    GO
                  </Button>
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  event(undefined, 'view_feature', { feature: 'trip_search', page: 'landing' });
                  history.push('/trip-search');
                }}
              >
                <ListItemIcon>
                  <Avatar className={classes.bigAvatar} alt="Trips" src={'/images/svg/customer-review.svg'} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                    >
                      Search Trips from Users Like You
                    </Typography>
                  }
                />
                <ListItemIcon>
                  <Button variant="outlined" style={{ color: 'green' }}>
                    GO
                  </Button>
                </ListItemIcon>
              </ListItem>
              {user === null ? (
                <ListItem variant="outlined" button onClick={() => handleLogin('Google')}>
                  <ListItemIcon>
                    <Avatar className={classes.bigAvatar} alt="Trips" src={'/images/svg/google.svg'} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        type="body2"
                        style={{
                          color: colourScheme[globalTheme.colourScheme].primaryText,
                          fontFamily: 'Cardo'
                        }}
                      >
                        Create an Account and Manage Your Trip on Any Device
                      </Typography>
                    }
                  />
                  <ListItemIcon>
                    <Button variant="outlined" style={{ color: 'green' }}>
                      GO
                    </Button>
                  </ListItemIcon>
                </ListItem>
              ) : null}
              <ListItem
                button
                onClick={() => {
                  event(undefined, 'view_feature', { feature: 'dark_mode', page: 'landing' });
                  putThemeAction({
                    colourScheme: globalTheme.colourScheme !== 'dark' ? 'dark' : 'light',
                    hd: globalTheme.hd
                  });
                }}
              >
                <ListItemIcon>
                  <Avatar className={classes.bigAvatar} alt="Trips" src={'/images/svg/half-moon.svg'} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      type="body2"
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                    >
                      {globalTheme.colourScheme !== 'dark' ? 'Try New Dark Mode!' : 'Return to the Light!'}
                    </Typography>
                  }
                />
                <ListItemIcon>
                  <Button variant="outlined" style={{ color: 'green' }}>
                    GO
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
        </Paper>
        <div style={{ textAlign: 'center', marginTop: '18px', marginBottom: '12px' }}>
          <Typography
            style={{
              color: colourScheme[globalTheme.colourScheme].primaryText,
              fontFamily: 'Oswald'
            }}
            variant="h4"
          >
            Top Destinations
          </Typography>
          <Divider
            style={{
              background: colourScheme[globalTheme.colourScheme].primaryText
            }}
          />
        </div>
        <Grid spacing={3} container justify="center" alignItems="center">
          <Grid item xs={12} sm={4}>
            <LazyCityCard
              visited={visited}
              wantToGo={wantToGo}
              climate={climate}
              selected={selected}
              savedTrips={trips}
              changeTrip={changeTrip}
              history={history}
              addToVisited={updateVisitedAddAction}
              removeFromVisited={updateVisitedRemoveAction}
              addToWantToGo={updateWantToGoAddAction}
              removeFromWantToGo={updateWantToGoRemoveAction}
              user={user}
              city={cities['london']}
              cityName={'london'}
              globalTheme={globalTheme}
              createTrip={createTrip}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LazyCityCard
              visited={visited}
              wantToGo={wantToGo}
              climate={climate}
              selected={selected}
              savedTrips={trips}
              changeTrip={changeTrip}
              history={history}
              addToVisited={updateVisitedAddAction}
              removeFromVisited={updateVisitedRemoveAction}
              addToWantToGo={updateWantToGoAddAction}
              removeFromWantToGo={updateWantToGoRemoveAction}
              user={user}
              city={cities['santorini']}
              cityName={'santorini'}
              globalTheme={globalTheme}
              createTrip={createTrip}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <LazyCityCard
              visited={visited}
              wantToGo={wantToGo}
              climate={climate}
              selected={selected}
              savedTrips={trips}
              changeTrip={changeTrip}
              history={history}
              addToVisited={updateVisitedAddAction}
              removeFromVisited={updateVisitedRemoveAction}
              addToWantToGo={updateWantToGoAddAction}
              removeFromWantToGo={updateWantToGoRemoveAction}
              user={user}
              city={cities['istanbul']}
              cityName={'istanbul'}
              globalTheme={globalTheme}
              createTrip={createTrip}
            />
          </Grid>
        </Grid>
        <Button
          style={{ marginTop: '18px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            event(undefined, 'view_feature', { feature: 'destinations_button', page: 'landing' });
            history.push('/cities');
          }}
        >
          Filter Destinations For You
        </Button>
        <Grid spacing={3} container justify="center" alignItems="center" style={{ marginTop: '24px' }}>
          <Grid item>
            <SocialIcon
              className={classes.socialIcon}
              url="https://www.facebook.com/travelatlasofficial/"
              onClick={() => {
                event(undefined, 'view_feature', { feature: 'social_facebook', page: 'landing' });
              }}
            />
          </Grid>
          <Grid item>
            <SocialIcon
              className={classes.socialIcon}
              url="https://www.pinterest.ca/TravelAtlasOfficial/"
              onClick={() => {
                event(undefined, 'view_feature', { feature: 'social_pinterest', page: 'landing' });
              }}
            />
          </Grid>
          <Grid item>
            <SocialIcon
              className={classes.socialIcon}
              url="https://twitter.com/TravelAtlas2"
              onClick={() => {
                event(undefined, 'view_feature', { feature: 'social_twitter', page: 'landing' });
              }}
            />
          </Grid>
          <Grid item>
            <SocialIcon
              className={classes.socialIcon}
              url="https://www.instagram.com/travelatlasofficial/"
              onClick={() => {
                event(undefined, 'view_feature', { feature: 'social_instagram', page: 'landing' });
              }}
            />
          </Grid>
          <Grid item>
            <a
              href="https://www.tiktok.com/@travelatlas/"
              onClick={() => {
                event(undefined, 'view_feature', { feature: 'social_tiktok', page: 'landing' });
              }}
            >
              <img className={classes.socialIcon} src="/images/svg/tiktok.svg" alt={'Travel-Atlas TikTok'} />
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  visited: state.visited,
  wantToGo: state.wantToGo,
  trips: state.trips,
  climate: state.climate,
  selected: state.selected,
  globalTheme: state.theme,
  searchValue: state.searchValue,
  toolbarState: state.toolbarState,
  search: state.search,
  user: state.user,
  selectedCountries: state.selectedCountries,
  selectedContinents: state.selectedContinents
});

const mapDispatchToProps = (dispatch) => ({
  updateVisitedAddAction: (user, set, item) => addToVisited(dispatch, user, set, item),
  updateVisitedRemoveAction: (user, set, item) => removeFromVisited(dispatch, user, set, item),
  updateWantToGoAddAction: (user, set, item) => addToWantToGo(dispatch, user, set, item),
  updateWantToGoRemoveAction: (user, set, item) => removeFromWantToGo(dispatch, user, set, item),
  putToolbarState: (value) => dispatch(putToolbarStateAction(value)),
  changeTrip: (user, trip, indexOfTrip) => updateSingleTrip(dispatch, user, trip, indexOfTrip),
  updateCountries: (newValue) => dispatch(putSelectedCountriesAction(newValue)),
  updateContinents: (newValue) => dispatch(putSelectedContinentsAction(newValue)),
  updateClimateAction: (value) => dispatch(putClimateAction(value)),
  createTrip: (user, trips, tripName, tripObject) => createNewTrip(dispatch, user, trips, tripName, tripObject),
  putThemeAction: (value) => dispatch(putTheme(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Landing));
