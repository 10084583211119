import React, { useEffect, useState } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';
import { Route, useHistory, useLocation, Redirect } from 'react-router-dom';
import SwitchWithSlide from './transitions/SwitchWithSlide';
import { connect } from 'react-redux';
import { updateAttributesAction } from './actions/attributes/updateAttributesAction';
import { colourScheme } from './util/colourSchemes';
import { event, pageview } from './util/tracking';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Auth from '@aws-amplify/auth';
import { updateLocalUserState } from './repository/user/user';
import { loginUser } from './repository/user/authState';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import './styles/overrides.css';
import { updatePrivacyAction } from './actions/privacy/updatePrivacyAction';
import Feedback from './pages/Feedback';
import * as Sentry from '@sentry/react';
import Landing from './pages/Landing';
import TripSearch from './pages/TripSearch';
import Profile from './pages/Profile';

const Cities = React.lazy(() => import('./pages/Cities'));
const Countries = React.lazy(() => import('./pages/Countries'));
const ContentPage = React.lazy(() => import('./pages/ContentPage'));
const City = React.lazy(() => import('./pages/City'));
const NoMatch = React.lazy(() => import('./components/NoMatch'));
const ToolbarDrawer = React.lazy(() => import('./components/ToolbarDrawer'));
const SavedSearches = React.lazy(() => import('./pages/Searches'));
const VisitedDestinations = React.lazy(() => import('./pages/Visited'));
const WantToGo = React.lazy(() => import('./pages/WantToGo'));
const TripCollaborator = React.lazy(() => import('./pages/TripCollaborator'));

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
    success: {
      main: green[500],
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

function App(props) {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { theme: globalTheme, privacy, acceptPrivacy } = props;

  const initializeData = async () => {
    const userData = await Auth.currentUserInfo();
    const { login, logout, user, visited, wantToGo, search, trips } = props;
    if (userData) {
      setLoading(true);
      await login(userData, visited, wantToGo, search, trips);
    } else if (!userData && user) {
      await logout();
    }
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      // prompt user to confirm refresh
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });
    initializeData();
  }, []);

  useEffect(() => {
    pageview();
    history.listen(() => pageview());
  }, [history]);

  useEffect(() => {
    document.body.style.backgroundColor = colourScheme[globalTheme.colourScheme].background;
  }, [globalTheme.colourScheme, globalTheme]);

  return (
    <div
      className="App"
      style={{
        backgroundColor: colourScheme[globalTheme.colourScheme].background,
        background: colourScheme[globalTheme.colourScheme].background
      }}
    >
      <MuiThemeProvider theme={theme}>
        <React.Suspense
          fallback={
            <Grid container style={{ marginTop: '72px' }} justify={`center`} align={`center`}>
              <CircularProgress size={72} />
            </Grid>
          }
        >
          <ToolbarDrawer history={history} location={location}>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={!privacy}
              message={
                'We serve cookies on this site to analyze traffic, remember your preferences, and optimize your experience.'
              }
              action={
                <React.Fragment>
                  <Button
                    onClick={() => {
                      event(undefined, 'privacy', { method: 'more_details' });
                      history.push('/content/privacy');
                    }}
                    color="primary"
                  >
                    More Details
                  </Button>
                  <Button
                    onClick={() => {
                      event(undefined, 'privacy', { method: 'accept' });
                      acceptPrivacy();
                    }}
                    color="primary"
                  >
                    OK
                  </Button>
                </React.Fragment>
              }
            />
            <SwitchWithSlide updateStep={(...currentStep) => this.setState({ currentStep })}>
              <Route
                exact
                path="/"
                render={(routeProps) => <Landing history={history} loading={loading} {...routeProps} />}
              />
              <Route
                exact
                path="/cities"
                render={(routeProps) => <Cities history={history} loading={loading} {...routeProps} />}
              />
              <Route path="/countries" render={(routeProps) => <Countries history={history} {...routeProps} />} />
              <Route path="/trip-search" render={(routeProps) => <TripSearch history={history} {...routeProps} />} />
              <Route path="/profile" render={(routeProps) => <Profile history={history} {...routeProps} />} />
              <Route path="/city" render={(routeProps) => <City {...routeProps} />} />
              <Route path="/content" render={(routeProps) => <ContentPage {...routeProps} />} />
              <Route path="/saved/searches" render={(routeProps) => <SavedSearches />} />
              <Route path="/saved/visited" render={(routeProps) => <VisitedDestinations />} />
              <Route path="/saved/trips" render={(routeProps) => <TripCollaborator history={history} />} />
              <Route path="/saved/want-to-go" render={(routeProps) => <WantToGo />} />
              <Route path="/give-feedback" render={(routeProps) => <Feedback />} />
              <Route render={(routeProps) => <NoMatch globalTheme={globalTheme} />} />
            </SwitchWithSlide>
          </ToolbarDrawer>
        </React.Suspense>
      </MuiThemeProvider>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = (dispatch) => ({
  updateAttributesAction: (val) => dispatch(updateAttributesAction(val)),
  acceptPrivacy: () => dispatch(updatePrivacyAction(true)),
  login: async (userData, visited, wantToGo, searches, trips) =>
    await loginUser(dispatch, userData, visited, wantToGo, searches, trips),
  logout: async () => await updateLocalUserState(dispatch, null, [], [], {}, [])
});

export default connect(mapStateToProps, mapDispatchToProps)(Sentry.withProfiler(App));
