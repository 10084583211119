import React, { Component } from 'react';
import VizSensor from 'react-visibility-sensor';
import { withRouter } from 'react-router-dom';
import { getCoverImage } from '../../util/images';
import ExpandableCityCard from '../Map/ExpandableCityCard';

class LazyCityCard extends Component {
  state = {
    wasViz: false
  };

  // TODO:: Break this into multiple functions
  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const {
      visited,
      wantToGo,
      climate,
      savedTrips,
      changeTrip,
      selected,
      history,
      addToVisited,
      removeFromVisited,
      addToWantToGo,
      removeFromWantToGo,
      user,
      cityName,
      globalTheme,
      createTrip
    } = this.props;

    const visitedSet = new Set(visited);
    const wantToGoSet = new Set(wantToGo);

    return (
      <VizSensor
        partialVisibility={true}
        onChange={(isVisible) => {
          this.setState({ wasViz: this.state.wasViz || isVisible });
        }}
        active={!this.state.wasViz}
      >
        <ExpandableCityCard
          name={cityName}
          src={getCoverImage(cityName, 360)}
          history={history}
          visitedSet={visitedSet}
          wantToGoSet={wantToGoSet}
          visited={visited}
          wantToGo={wantToGo}
          user={user}
          addToVisited={addToVisited}
          removeFromVisited={removeFromVisited}
          addToWantToGo={addToWantToGo}
          removeFromWantToGo={removeFromWantToGo}
          trips={savedTrips}
          globalTheme={globalTheme}
          changeTrip={changeTrip}
          isVisible={this.state.wasViz}
          elevation={true}
          climate={climate}
          selected={selected}
          createTrip={createTrip}
        />
      </VizSensor>
    );
  }
}

export default withRouter((props) => <LazyCityCard {...props} />);
