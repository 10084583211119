/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onPutUser = /* GraphQL */ `
  subscription OnPutUser($id: ID!) {
    onPutUser(id: $id) {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onEditTrip = /* GraphQL */ `
  subscription OnEditTrip($id: ID!) {
    onEditTrip(id: $id) {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrip = /* GraphQL */ `
  subscription OnCreateTrip {
    onCreateTrip {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrip = /* GraphQL */ `
  subscription OnUpdateTrip {
    onUpdateTrip {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrip = /* GraphQL */ `
  subscription OnDeleteTrip {
    onDeleteTrip {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTripEditor = /* GraphQL */ `
  subscription OnCreateTripEditor {
    onCreateTripEditor {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTripEditor = /* GraphQL */ `
  subscription OnUpdateTripEditor {
    onUpdateTripEditor {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTripEditor = /* GraphQL */ `
  subscription OnDeleteTripEditor {
    onDeleteTripEditor {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback {
    onCreateFeedback {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback {
    onUpdateFeedback {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback {
    onDeleteFeedback {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
