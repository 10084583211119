import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { deSerializeUser, pullRemoteUserState, updateLocalUserState, updateRemoteUserState } from './user';
import { setupUserSubscriptionsGraph } from './subscriptions';
import { getUser } from '../../graphql/queries';
import { createUser } from '../../graphql/mutations';
import { setupTripSubscriptionsGraph } from '../trip/subscriptions';
import { createNewTrip, resolveUserTrips } from '../trip/trip';

/**
 * Unions data that was created before login into logged-in user
 *
 * @param user The full user data object
 * @param visited To add to the user's properties
 * @param wantToGo To add to the user's properties
 * @param searches To add to the user's properties
 * @returns {*}
 */
const unionUserProperties = (user, visited, wantToGo, searches) => {
  user.visited = [...new Set([...visited, ...user.visited])];
  user.wantToGo = [...new Set([...wantToGo, ...user.wantToGo])];
  if (!user.search) {
    user.search = {};
  }
  user.search = Object.assign(user.search, searches);
  return user;
};

/**
 * Takes trips that were created offline and creates them in the remote when a user logs in and assigns them to that user
 *
 * @param dispatch
 * @param user
 * @param remoteTrips Trips that already exist in the database
 * @param localTrips Trips that only exist in the local storage
 */
const createOfflineTrips = async (dispatch, user, remoteTrips, localTrips) => {
  for (const trip of localTrips) {
    if (!trip.id) {
      try {
        await createNewTrip(dispatch, user, remoteTrips, trip.name, JSON.parse(trip.tripObject));
      } catch (error) {
        console.error('Failed to convert offline trip to remote trip', user, remoteTrips, trip);
      }
    }
  }
};

/**
 * Updates the local state of user properties and then pushes the changes to graphql remotely
 *
 * @param dispatch
 * @param sessionUser The full user data object
 * @param visited To add to the user's properties
 * @param wantToGo To add to the user's properties
 * @param searches To add to the user's properties
 * @param trips To add to the user's properties
 */
export const loginUser = async (dispatch, sessionUser, visited, wantToGo, searches, trips) => {
  let existingUser;
  const metaData = {
    profilePicture: sessionUser.attributes.picture,
    email: sessionUser.attributes.email,
    emailConfirmed: sessionUser.attributes.email_verified
  };
  existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: sessionUser.username }));
  if (existingUser !== undefined && existingUser.data !== undefined && existingUser.data.getUser) {
    try {
      sessionUser = existingUser.data.getUser;
      Object.assign(sessionUser, metaData);
      sessionUser = deSerializeUser(sessionUser);
      sessionUser = unionUserProperties(sessionUser, visited, wantToGo, searches);
    } catch (error) {
      console.error(
        'Failed to deserialize and union properties of user',
        error,
        sessionUser,
        visited,
        wantToGo,
        searches,
        metaData
      );
    }

    try {
      await updateLocalUserState(dispatch, sessionUser, sessionUser.visited, sessionUser.wantToGo, sessionUser.search);
      sessionUser = await updateRemoteUserState(dispatch, sessionUser);
    } catch (error) {
      console.error('Failed to update user in login function', sessionUser, error);
    }
  } else {
    sessionUser = {
      id: sessionUser.username,
      username: sessionUser.username,
      visited: JSON.stringify([]),
      wantToGo: JSON.stringify([]),
      search: JSON.stringify({})
    };
    Object.assign(sessionUser, metaData);
    try {
      const createdUser = await GraphQLAPI.graphql(graphqlOperation(createUser, { input: sessionUser }));
      sessionUser = createdUser.data.createUser;
    } catch (error) {
      console.error('Failed to create user in login function', sessionUser);
    }
  }
  sessionUser = unionUserProperties(sessionUser, visited, wantToGo, searches);
  const remoteTrips = await resolveUserTrips(sessionUser);
  if (trips.length > 0) {
    await createOfflineTrips(dispatch, sessionUser, remoteTrips, trips);
  }
  sessionUser = await pullRemoteUserState(dispatch, sessionUser, 'loginUser');
  await setupUserSubscriptionsGraph(dispatch, sessionUser);
  await setupTripSubscriptionsGraph(dispatch, sessionUser);
  window.gtag('config', 'MEASUREMENT_ID', {
    user_id: sessionUser.id
  });
  return sessionUser;
};
