import { CaptureConsole, Dedupe, ExtraErrorData } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';

class SentryLogging {
  constructor() {
    const environment = process.env.REACT_APP_SENTRY_RELEASE;

    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://7ac0c95142d6458b9d2b8171c69c2f1b@o457191.ingest.sentry.io/5453400',
        release: environment,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({ levels: ['error'] }),
          new Dedupe(),
          new ExtraErrorData()
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
        whitelistUrls: [/travel-atlas\.com/],
        normalizeDepth: 10 // Or however deep you want your state context to be.
      });
    }
  }

  log(level, message, extras) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        for (const [key, value] of Object.entries(extras)) {
          scope.setExtra(key, value);
        }
        Sentry.captureMessage(message, level);
      });
    } else {
      console[level](message, extras);
    }
  }
}

export default SentryLogging;
