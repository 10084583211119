import React, { useState } from 'react';
import { ColouredLogo, colourScheme } from '../util/colourSchemes';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { event } from '../util/tracking';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { addToVisited, removeFromVisited } from '../repository/user/visited';
import { addToWantToGo, removeFromWantToGo } from '../repository/user/wantToGo';
import { putToolbarStateAction } from '../actions/toolbarState/putToolbarStateAction';
import { createNewTrip, updateSingleTrip } from '../repository/trip/trip';
import { putSelectedCountriesAction } from '../actions/selectedCountries/putSelectedCountriesAction';
import { putSelectedContinentsAction } from '../actions/selectedContinents/putSelectedContinentsAction';
import { putClimateAction } from '../actions/climate/putClimateAction';
import {
  Box,
  Card,
  CardActions,
  Chip,
  Container,
  FormControl,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { putTheme } from '../actions/theme/putTheme';
import { getCoverImage } from '../util/images';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import OpenInAppIcon from 'mdi-react/OpenInAppIcon';
import HeartOutlineIcon from 'mdi-react/HeartOutlineIcon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import CheckOutlineIcon from 'mdi-react/CheckOutlineIcon';
import { StarOutline } from '@material-ui/icons';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ATTRIBUTES } from '../util/filters';
import { toTitleCase } from '../util/textFormatting';
import cities from '../data/dataSheet.json';
import countries from '../data/countries.json';
import Auth from '@aws-amplify/auth';
import GoogleButton from 'react-google-button';

const styles = (theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    transition: 'opacity 700ms linear',
    transitionDelay: '100ms'
  },
  bigAvatar: {
    borderStyle: 'solid',
    borderWidth: '3px',
    borderColor: '#2196f3',
    height: '80px',
    width: '80px',
    cursor: 'pointer',
    WebkitTransition: '1s',
    transition: '1s'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
});

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const handleLogin = async (provider) => {
  event(
    {
      category: 'Authentication',
      action: 'Clicked Feature Login',
      label: 'Google'
    },
    'login',
    { method: 'Google' }
  );
  await Auth.federatedSignIn({ provider });
};

const getPassportImage = (country) => {
  const colour = ['EU'].includes(country.continent) ? 'Red' : ['AF'].includes(country.continent) ? 'Green' : 'Blue';
  return `/images/svg/passport${colour}.svg`;
};

/* TODO: Make this into a component instead of a page */

function Profile(props) {
  const { visited, wantToGo, history, globalTheme, user, trips, classes, component } = props;

  const [tripType, setTripType] = useState([]);
  const [accommodationType, setAccommodationType] = useState([]);
  const [transportType, setTransportType] = useState([]);
  const [lifestyleType, setLifestyleType] = useState([]);
  const [eatingType, setEatingType] = useState([]);
  const [attributesPreference, setAttributesPreference] = useState([]);
  const [baseCities, setBaseCities] = useState([]);
  const [passports, setPassports] = useState([]);

  const tripObjects = trips.map((trip) => JSON.parse(trip.tripObject));

  const tripCards = (
    <Grid
      style={{ marginTop: '12px', marginBottom: '24px' }}
      spacing={3}
      container
      justify={'center'}
      alignItems={'center'}
    >
      {trips.map((trip, index) => (
        <Grid item key={index} xs={12} md={6} lg={4}>
          <Card style={{ padding: '12px', background: colourScheme[globalTheme.colourScheme].cardColour }}>
            <Grid spacing={1} container justify={'center'} alignItems={'center'}>
              {tripObjects[index].cities.map((city, index) => (
                <Grid style={{ width: '100%', height: '100%' }} item key={city + index} xs={4}>
                  <CardMedia
                    className={classes.media}
                    image={getCoverImage(city, 240)}
                    alt={city}
                    style={{ borderRadius: '4px' }}
                  />
                </Grid>
              ))}
              {tripObjects[index].cities.length === 0 ? (
                <Grid style={{ width: '100%', height: '100%' }} item xs={4}>
                  <CardMedia
                    className={classes.media}
                    image={'https://travel-atlas.com/image/static/city.webp'}
                    alt={'Empty trip'}
                    style={{ borderRadius: '4px' }}
                  />
                </Grid>
              ) : null}
            </Grid>
            <CardContent>
              <Typography
                variant={'h6'}
                style={{
                  color: colourScheme[globalTheme.colourScheme].primaryText,
                  textAlign: 'center'
                }}
              >
                {trip.name}
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container justify={'center'} alignItems={'center'}>
                <Button
                  color={'primary'}
                  onClick={() => {
                    event(undefined, 'continue_planning', { page: 'landing' });
                    history.push('saved/trips');
                  }}
                >
                  <OpenInAppIcon />
                  Continue Planning
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const userDetails = (
    <Paper
      style={{
        padding: '12px',
        marginTop: '12px',
        marginBottom: '12px',
        background: colourScheme[globalTheme.colourScheme].cardColour
      }}
    >
      {user ? (
        <>
          <Typography
            variant={'h2'}
            component={'h1'}
            style={{ textAlign: 'center', color: colourScheme[globalTheme.colourScheme].primaryText }}
          >
            Profile
          </Typography>
          <Divider
            style={{
              background: colourScheme[globalTheme.colourScheme].primaryText
            }}
          />
          <Grid
            onClick={() => history.push('/profile')}
            container
            justify="center"
            alignItems="center"
            direction="column"
            style={{ marginTop: '12px', marginBottom: '12p' }}
          >
            <Avatar alt="User profile picture" src={user.profilePicture} className={classes.bigAvatar} />
            <Typography style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>{user.email}</Typography>
          </Grid>
          <Divider
            style={{
              background: colourScheme[globalTheme.colourScheme].primaryText,
              marginBottom: '12px'
            }}
          />
        </>
      ) : null}
      <Grid spacing={3} container justify={'center'} alignItems={'center'} style={{ marginBottom: '12px' }}>
        <Grid item xs={4}>
          <Paper
            style={{ background: '#43a047', padding: '10px', cursor: 'pointer', textAlign: 'center' }}
            onClick={() => {
              event(undefined, 'profile_counter_click', { page: 'landing', feature: 'want_to_go' });
              history.push('/saved/want-to-go');
            }}
          >
            <div style={{ background: '#00701a', padding: '10px' }}>
              <HeartOutlineIcon style={{ color: 'white' }} />
              <Typography style={{ color: 'white' }} variant={'h2'}>
                <Box fontWeight="fontWeightBold" display="inline">
                  {wantToGo.length}
                </Box>
              </Typography>
              <Typography style={{ color: 'white' }} variant={'body2'}>
                Places You Want to Go
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            style={{ background: '#e53935', padding: '10px', cursor: 'pointer', textAlign: 'center' }}
            onClick={() => {
              event(undefined, 'profile_counter_click', { page: 'landing', feature: 'visited' });
              history.push('/saved/visited');
            }}
          >
            <div style={{ background: '#ab000d', padding: '10px' }}>
              <CheckOutlineIcon style={{ color: 'white' }} />
              <Typography style={{ color: 'white' }} variant={'h2'}>
                <Box fontWeight="fontWeightBold" display="inline">
                  {visited.length}
                </Box>
              </Typography>
              <Typography style={{ color: 'white' }} variant={'body2'}>
                Visited Cities
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            style={{ background: '#1e88e5', padding: '10px', cursor: 'pointer', textAlign: 'center' }}
            onClick={() => {
              event(undefined, 'profile_counter_click', { page: 'landing', feature: 'trips' });
              history.push('/saved/trips');
            }}
          >
            <div style={{ background: '#005cb2', padding: '10px' }}>
              <StarOutline style={{ color: 'white' }} />
              <Typography style={{ color: 'white' }} variant={'h2'}>
                <Box fontWeight="fontWeightBold" display="inline">
                  {trips.length}
                </Box>
              </Typography>
              <Typography style={{ color: 'white' }} variant={'body2'}>
                Trips Created
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );

  const preferences = (
    <>
      <Paper
        style={{
          padding: '12px',
          background: colourScheme[globalTheme.colourScheme].cardColour
        }}
      >
        <Typography
          variant={'h3'}
          style={{ textAlign: 'center', color: colourScheme[globalTheme.colourScheme].primaryText }}
        >
          Trip Preferences
        </Typography>
        <Typography
          variant={'body1'}
          style={{ textAlign: 'center', color: colourScheme[globalTheme.colourScheme].primaryText }}
        >
          What kind of traveller are you?
        </Typography>
        <Grid container justify={'center'} alignItems={'center'}>
          <List>
            <ListItem>
              <ListItemText style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                With whom
              </ListItemText>
              <ListItemText>
                <ToggleButtonGroup
                  value={tripType}
                  onChange={(mouseEvent, newValue) => {
                    event(undefined, 'change_profile', { profile_feature: 'trip_type', value: newValue.toString() });
                    setTripType(newValue);
                  }}
                  aria-label="Type of Trip"
                  size="small"
                >
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="solo"
                    aria-label="solo"
                  >
                    Solo
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="friends"
                    aria-label="friends"
                  >
                    With Friends
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="family"
                    aria-label="family"
                  >
                    With Family
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                Accommodation
              </ListItemText>
              <ListItemText>
                <ToggleButtonGroup
                  value={accommodationType}
                  onChange={(mouseEvent, newValue) => {
                    event(undefined, 'change_profile', {
                      profile_feature: 'accommodation_type',
                      value: newValue.toString()
                    });
                    setAccommodationType(newValue);
                  }}
                  aria-label="Type of Accommodation"
                  size="small"
                >
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="hotel"
                    aria-label="hotel"
                  >
                    Hotel
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="hostel"
                    aria-label="hostel"
                  >
                    Hostel
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="airbnb"
                    aria-label="airbnb"
                  >
                    AirBnB
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                Transport
              </ListItemText>
              <ListItemText>
                <ToggleButtonGroup
                  value={transportType}
                  onChange={(mouseEvent, newValue) => {
                    event(undefined, 'change_profile', {
                      profile_feature: 'transport_type',
                      value: newValue.toString()
                    });
                    setTransportType(newValue);
                  }}
                  aria-label="Type of Transport"
                  size="small"
                >
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="plane"
                    aria-label="plane"
                  >
                    Plane
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="train"
                    aria-label="train"
                  >
                    Train
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="bus"
                    aria-label="bus"
                  >
                    Bus
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="road"
                    aria-label="road"
                  >
                    Road Trip
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                Lifestyle
              </ListItemText>
              <ListItemText>
                <ToggleButtonGroup
                  value={lifestyleType}
                  onChange={(mouseEvent, newValue) => {
                    event(undefined, 'change_profile', {
                      profile_feature: 'lifestyle_type',
                      value: newValue.toString()
                    });
                    setLifestyleType(newValue);
                  }}
                  aria-label="Type of Lifestyle"
                  size="small"
                >
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="cheap"
                    aria-label="cheap"
                  >
                    Cheap
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="moderate"
                    aria-label="moderate"
                  >
                    Moderate
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="luxury"
                    aria-label="luxury"
                  >
                    Luxury
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText style={{ color: colourScheme[globalTheme.colourScheme].primaryText, marginRight: '12px' }}>
                Food
              </ListItemText>
              <ListItemText>
                <ToggleButtonGroup
                  value={eatingType}
                  onChange={(mouseEvent, newValue) => {
                    event(undefined, 'change_profile', {
                      profile_feature: 'food',
                      value: newValue.toString()
                    });
                    setEatingType(newValue);
                  }}
                  aria-label="Type of Transport"
                  size="small"
                >
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="restaurant"
                    aria-label="plane"
                  >
                    Restaurant
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="takeaway"
                    aria-label="train"
                  >
                    Takeaway / Delivery
                  </ToggleButton>
                  <ToggleButton
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    value="home"
                    aria-label="bus"
                  >
                    Home Cooking
                  </ToggleButton>
                </ToggleButtonGroup>
              </ListItemText>
            </ListItem>
            <ListItem>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="favourite-attributes-multiple-chip-label"
                  style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                >
                  Favourite Things
                </InputLabel>
                <Select
                  labelId="favourite-attributes-multiple-chip-label"
                  id="favourite-attributes-multiple-chip"
                  multiple
                  value={attributesPreference}
                  onChange={(changeEvent) => {
                    const options = changeEvent.target.value;
                    event(undefined, 'change_profile', {
                      profile_feature: 'attributes',
                      value: options.toString()
                    });
                    setAttributesPreference(options);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={toTitleCase(value.replace(/-/gi, ' '))}
                          className={classes.chip}
                          avatar={<Avatar alt={value} src={`https://travel-atlas.com/image/icons/${value}.svg`} />}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ATTRIBUTES.map((attribute) => (
                    <MenuItem key={attribute} value={attribute}>
                      {toTitleCase(attribute.replace(/-/gi, ' '))}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                  id="passports-multiple-chip-label"
                >
                  Passports Owned
                </InputLabel>
                <Select
                  labelId="passports-multiple-chip-label"
                  id="passports-multiple-chip"
                  multiple
                  value={passports}
                  onChange={(changeEvent) => {
                    const options = changeEvent.target.value;
                    event(undefined, 'change_profile', {
                      profile_feature: 'passports',
                      value: options.toString()
                    });
                    setPassports(options);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={toTitleCase(countries[value].name)}
                          className={classes.chip}
                          avatar={<Avatar alt={value} src={getPassportImage(countries[value])} />}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(countries)
                    .sort()
                    .map((code) => (
                      <MenuItem key={code} value={code}>
                        {toTitleCase(countries[code].name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl className={classes.formControl}>
                <InputLabel
                  style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                  id="home-city-multiple-chip-label"
                >
                  Based In
                </InputLabel>
                <Select
                  labelId="home-city-multiple-chip-label"
                  id="home-city-multiple-chip"
                  multiple
                  value={baseCities}
                  onChange={(changeEvent) => {
                    const options = changeEvent.target.value;
                    event(undefined, 'change_profile', {
                      profile_feature: 'base_cities',
                      value: options.toString()
                    });
                    setBaseCities(options);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={toTitleCase(value)}
                          className={classes.chip}
                          avatar={<Avatar alt={value} src={getCoverImage(value, 240)} />}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(cities)
                    .sort()
                    .map((city) => (
                      <MenuItem key={city} value={city}>
                        {toTitleCase(city)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </ListItem>
          </List>
        </Grid>
      </Paper>
    </>
  );

  return component ? (
    <>
      {tripCards}
      {wantToGo.length > 0 || visited.length > 0 || trips.length > 0 ? userDetails : null}
    </>
  ) : (
    <Container>
      {user ? (
        <>
          {userDetails}
          {preferences}
          {tripCards}
        </>
      ) : (
        <Grid container justify="center" alignItems="center" direction={'column'} style={{ marginTop: '72px' }}>
          <ColouredLogo size={'320px'} theme={globalTheme} alt="travel-atlas logo" />
          <GoogleButton
            style={{
              boxShadow:
                '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 4px 7px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            }}
            type={globalTheme.colourScheme === 'dark' ? 'dark' : 'light'}
            onClick={() => handleLogin('Google')}
          />
          <Typography
            style={{
              padding: '12px',
              textAlign: 'center',
              color: colourScheme[globalTheme.colourScheme].primaryText,
              marginBottom: '24px'
            }}
          >
            You can only use this feature while logged-in.
          </Typography>
        </Grid>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  visited: state.visited,
  wantToGo: state.wantToGo,
  trips: state.trips,
  climate: state.climate,
  selected: state.selected,
  globalTheme: state.theme,
  searchValue: state.searchValue,
  toolbarState: state.toolbarState,
  search: state.search,
  user: state.user,
  selectedCountries: state.selectedCountries,
  selectedContinents: state.selectedContinents
});

const mapDispatchToProps = (dispatch) => ({
  updateVisitedAddAction: (user, set, item) => addToVisited(dispatch, user, set, item),
  updateVisitedRemoveAction: (user, set, item) => removeFromVisited(dispatch, user, set, item),
  updateWantToGoAddAction: (user, set, item) => addToWantToGo(dispatch, user, set, item),
  updateWantToGoRemoveAction: (user, set, item) => removeFromWantToGo(dispatch, user, set, item),
  putToolbarState: (value) => dispatch(putToolbarStateAction(value)),
  changeTrip: (user, trip, indexOfTrip) => updateSingleTrip(dispatch, user, trip, indexOfTrip),
  updateCountries: (newValue) => dispatch(putSelectedCountriesAction(newValue)),
  updateContinents: (newValue) => dispatch(putSelectedContinentsAction(newValue)),
  updateClimateAction: (value) => dispatch(putClimateAction(value)),
  createTrip: (user, trips, tripName, tripObject) => createNewTrip(dispatch, user, trips, tripName, tripObject),
  putThemeAction: (value) => dispatch(putTheme(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));
