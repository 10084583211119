exports.DEFAULT_CITY_IMAGE = 'https://travel-atlas.com/image/city.webp';

const convertCityNameToFileName = (cityName) => {
  // TODO:: need to fix filename so we can remove this exception
  if (['san marino', 'san sebastian', 'st gallen', 'the hague', 'cinque terre'].includes(cityName)) {
    return cityName.replace(' ', '');
  }
  if (cityName === 'washington dc') {
    return 'washington d.c.';
  }
  if (cityName === 'merida') {
    return 'mérida';
  }
  if (cityName === "st john's") {
    return 'st john_s';
  }
  return cityName;
};

const hasCities = (trip) => {
  return JSON.parse(trip.tripObject).cities.length > 0;
};

const getFirstCity = (trip) => {
  return hasCities(trip) ? JSON.parse(trip.tripObject).cities[0] : null;
};

exports.getCoverImage = (cityName, width = null) => {
  const urlBase = `https://travel-atlas.com/image/`;
  if (width === null) {
    return `${urlBase}original/${convertCityNameToFileName(cityName)}0_free.jpg`;
  }
  for (const preset of [240, 360, 480, 720]) {
    if (width <= preset) {
      return `${urlBase}${preset}/${convertCityNameToFileName(cityName)}0_free.${`jpg`}`;
    }
  }
  return `${urlBase}1080/${convertCityNameToFileName(cityName)}0_free.${`jpg`}`;
};

exports.getTripCoverImage = (trip, key = 0, width = null) => {
  return hasCities(trip) ? exports.getCoverImage(getFirstCity(trip), width) : `/images/svg/trip${key % 5}.svg`;
};

const generateFileName = (urlBase, cityName, resolution, imageNumber) => {
  return `${urlBase}${resolution}/${convertCityNameToFileName(cityName)}${imageNumber}_free.${`jpg`}`;
};

exports.getCityImages = (cityName, images = 1, width = null) => {
  const urlBase = `https://travel-atlas.com/image/`;
  const imageUrls = [];

  for (let i = 0; i < images; ++i) {
    if (width === null) {
      imageUrls.push(generateFileName(urlBase, cityName, 'original', i));
    } else {
      for (const preset of [240, 360, 480, 720, 1080]) {
        if (width <= preset) {
          imageUrls.push(generateFileName(urlBase, cityName, preset, i));
          break;
        }
      }
    }
  }
  return imageUrls;
};
