import React, { useState, useRef, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import MapIcon from 'mdi-react/MapIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import BeachIcon from 'mdi-react/BeachIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import debounce from 'lodash.debounce';
import { Button, Typography } from '@material-ui/core';
import countries from '../../data/countries.json';
import continents from '../../data/continents.json';
import cities from '../../data/dataSheet.json';
import { getCoverImage } from '../../util/images';
import { toTitleCase } from '../../util/textFormatting';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import CloseIcon from 'mdi-react/CloseIcon';
import { colourScheme } from '../../util/colourSchemes';
import { difference } from '../../util/sets';
import { event } from '../../util/tracking';
import { ATTRIBUTES, MONTHS, WEATHER } from '../../util/filters';
/* eslint-disable sonarjs/no-identical-functions */

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const setSearchParams = (
  history,
  localSearchValue,
  selectedAttributes,
  selectedContinents,
  selectedCountries,
  selectedClimate
) => {
  // convert object to a query string
  const search = new URLSearchParams();
  if (localSearchValue !== '') {
    search.append('search', localSearchValue);
  }
  for (const attribute of selectedAttributes) {
    search.append('filters', attribute);
  }
  for (const continent of Object.values(selectedContinents)) {
    search.append('continents', continent);
  }
  for (const country of Object.values(selectedCountries)) {
    search.append('countries', country);
  }
  for (const month of selectedClimate.months) {
    search.append('months', month);
  }
  for (const weather of selectedClimate.weather) {
    search.append('weather', weather);
  }

  history.push({ search: search.toString() });
};

const filterResults = (
  searchValue,
  focused,
  visited,
  wantToGo,
  selectedAttributes,
  selectedCountries,
  selectedContinents,
  selectedClimate,
  showAllAttributes,
  showAllRegions
) => {
  const selectedAttributesSet = new Set(selectedAttributes);
  const selectedCountriesSet = new Set(selectedCountries);
  const selectedContinentsSet = new Set(selectedContinents);
  let filteredCities = Object.values(cities)
    .filter((city) => city.name.toLowerCase().includes(searchValue.toLowerCase()))
    .slice(0, 5);
  let filteredCountries = Object.fromEntries(
    Object.entries(countries)
      .filter(
        ([code, country]) =>
          !selectedCountriesSet.has(code.toLowerCase()) &&
          country.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(0, 3)
  );
  let filteredContinents = Object.fromEntries(
    Object.entries(continents)
      .filter(
        ([code, continent]) =>
          !selectedContinentsSet.has(code.toLowerCase()) && continent.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(0, 2)
  );
  let filteredAttributes = ATTRIBUTES.filter(
    (attribute) => !selectedAttributesSet.has(attribute) && attribute.toLowerCase().includes(searchValue.toLowerCase())
  ).slice(0, 5);

  let filteredWeather =
    selectedClimate.weather.length > 0
      ? []
      : WEATHER.filter((weather) => weather.toLowerCase().includes(searchValue.toLowerCase()));

  let filteredMonths = MONTHS.filter(
    (month) => !selectedClimate.months.includes(month) && month.toLowerCase().includes(searchValue.toLowerCase())
  );

  if (searchValue === '' && focused) {
    filteredContinents = showAllRegions ? continents : {};
    filteredCountries = {};

    const displayCountries = showAllRegions ? Object.keys(countries) : ['IT', 'US', 'JP'];
    for (const code of displayCountries) {
      if (!selectedCountriesSet.has(code.toLowerCase())) {
        filteredCountries[code] = countries[code];
      }
    }

    filteredCities = [];
    filteredAttributes = Array.from(
      difference(
        new Set(showAllAttributes ? ATTRIBUTES : ['hiking', 'nightlife', 'cheap', 'beaches']),
        new Set(selectedAttributes)
      )
    );
    filteredWeather =
      selectedClimate.weather.length > 0
        ? []
        : Array.from(difference(new Set(WEATHER), new Set(selectedClimate.weather)));
    filteredMonths = Array.from(difference(new Set(MONTHS), new Set(selectedClimate.months)));
  }

  return { filteredCities, filteredCountries, filteredContinents, filteredAttributes, filteredWeather, filteredMonths };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function IntelligentSearch(props) {
  const {
    globalTheme,
    selectedAttributes,
    selectedClimate,
    searchValue,
    updateSearchValue,
    history,
    putToolbarState,
    toolbarState,
    visited,
    wantToGo,
    updateSelectedAddAction,
    updateSelectedRemoveAction,
    selectedCountries,
    selectedContinents,
    updateCountries,
    updateContinents,
    updateClimate,
    updateSelectedAction,
    hideMap
  } = props;
  const classes = useStyles();
  const [localSearchValue, setLocalSearchValue] = useState('');
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const [showAllRegions, setShowAllRegions] = useState(false);
  const [focused, setFocused] = useState(false);
  const selectedMonths = JSON.stringify(selectedClimate.months);
  const selectedWeather = JSON.stringify(selectedClimate.weather);

  useEffect(() => {
    let params = new URLSearchParams(history.location.search);
    const searchFilters = params.getAll('filters');
    const searchCountries = params.getAll('countries');
    const searchContinents = params.getAll('continents');
    const searchMonths = params.getAll('months');
    const searchWeather = params.getAll('weather');
    if (searchFilters.length > 0) {
      updateSelectedAction(searchFilters);
    }
    if (searchCountries.length > 0) {
      updateCountries(searchCountries);
    }
    if (searchContinents.length > 0) {
      updateContinents(searchContinents);
    }
    if (selectedMonths.length > 0 || selectedWeather.length > 0) {
      updateClimate({
        weather: searchWeather,
        months: searchMonths
      });
    }
    setLocalSearchValue(searchValue);
  }, []);

  useEffect(() => {
    setSearchParams(
      history,
      localSearchValue,
      selectedAttributes,
      selectedContinents,
      selectedCountries,
      selectedClimate
    );
  }, [localSearchValue, selectedAttributes, selectedCountries, selectedContinents, selectedMonths, selectedWeather]);

  const resetSearchValue = () => {
    setFocused(false);
    window.scrollTo(0, 0);
    setLocalSearchValue('');
    updateSearchValue('');
  };

  // This remains same across renders
  // highlight-starts
  const debouncedSave = useRef(
    debounce((nextValue) => {
      event(undefined, 'search', { search_term: nextValue });
      updateSearchValue(nextValue);
    }, 500)
  ).current;
  // highlight-ends
  const handleChange = (e) => {
    const { value: nextValue } = e.target;
    setLocalSearchValue(nextValue);
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue);
  };

  const visitedSet = new Set(visited);
  const wantToGoSet = new Set(wantToGo);
  const selectedCountriesSet = new Set(selectedCountries);
  const selectedContinentsSet = new Set(selectedContinents);

  const { filteredWeather, filteredAttributes, filteredContinents, filteredCountries, filteredCities, filteredMonths } =
    filterResults(
      searchValue,
      focused,
      visited,
      wantToGo,
      selectedAttributes,
      selectedCountries,
      selectedContinents,
      selectedClimate,
      showAllAttributes,
      showAllRegions
    );

  return (
    <>
      <Container>
        <Grid container justify={'center'} alignItems={'center'}>
          <Grid item xs={6} sm={2}>
            <Grid container justify={'center'} alignItems={'center'}>
              {selectedContinents.map((code) => (
                <Chip
                  key={code}
                  style={{
                    margin: '2px',
                    backgroundColor: '#009688'
                  }}
                  avatar={
                    <Avatar
                      alt={`${continents[code.toUpperCase()].toLowerCase()}`}
                      src={`/images/continents/${continents[code.toUpperCase()].toLowerCase()}.svg`}
                    />
                  }
                  label={`${toTitleCase(continents[code.toUpperCase()].toLowerCase())}`}
                  clickable
                  color="primary"
                  onDelete={() => {
                    selectedContinentsSet.delete(code);
                    updateContinents(Array.from(selectedContinentsSet));
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Grid container justify={'center'} alignItems={'center'}>
              {selectedCountries.map((code) => (
                <Chip
                  key={code}
                  style={{
                    margin: '2px',
                    backgroundColor: '#357a38'
                  }}
                  avatar={
                    <Avatar
                      alt={`${countries[code.toUpperCase()].name}`}
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code.toUpperCase()}.svg`}
                    />
                  }
                  label={`${toTitleCase(countries[code.toUpperCase()].name)}`}
                  clickable
                  color="primary"
                  onDelete={() => {
                    selectedCountriesSet.delete(code);
                    updateCountries(Array.from(selectedCountriesSet));
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Grid container justify={'center'} alignItems={'center'}>
              {selectedAttributes.map((attributeName) => (
                <Chip
                  key={attributeName}
                  style={{ margin: '2px' }}
                  avatar={
                    <Avatar
                      alt={`${attributeName}`}
                      src={`https://travel-atlas.com/image/icons/${attributeName}.svg`}
                    />
                  }
                  label={`${toTitleCase(attributeName.replace(/-/gi, ' '))}`}
                  clickable
                  color="primary"
                  onDelete={() => updateSelectedRemoveAction(attributeName)}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Grid container justify={'center'} alignItems={'center'}>
              {selectedClimate.weather.map((weather) => (
                <Chip
                  key={weather}
                  style={{
                    margin: '2px',
                    backgroundColor: '#f44336'
                  }}
                  avatar={<Avatar alt={`${weather}`} src={`https://travel-atlas.com/image/icons/${weather}.svg`} />}
                  label={`${toTitleCase(weather)}`}
                  clickable
                  color="primary"
                  onDelete={() => {
                    let selectedWeather = selectedClimate.weather;
                    selectedWeather = selectedWeather.filter((val) => val !== weather);
                    updateClimate({
                      weather: selectedWeather,
                      months: selectedClimate.months
                    });
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Grid container justify={'center'} alignItems={'center'}>
              {selectedClimate.months.map((month) => (
                <Chip
                  key={month}
                  style={{
                    margin: '2px',
                    backgroundColor: '#673ab7'
                  }}
                  avatar={<Avatar alt={`${month}`} src={`https://travel-atlas.com/image/icons/${month}.svg`} />}
                  label={`${toTitleCase(month)}`}
                  clickable
                  color="primary"
                  onDelete={() => {
                    let selectedMonths = selectedClimate.months;
                    selectedMonths = selectedMonths.filter((val) => val !== month);
                    updateClimate({
                      weather: selectedClimate.weather,
                      months: selectedMonths
                    });
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ marginTop: '12px' }}>
          <Paper
            style={{
              background: colourScheme[globalTheme.colourScheme].cardColour
            }}
            onFocus={() => setFocused(true)}
          >
            <div className={classes.root}>
              <Tooltip
                title={`See the filters by category`}
                aria-label={`See the filters by category`}
                placement={`top`}
              >
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="search"
                  onClick={() => putToolbarState({ searchDrawer: true, sidebarOpen: toolbarState.sidebarOpen })}
                >
                  <FilterIcon />
                </IconButton>
              </Tooltip>
              <InputBase
                style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                className={classes.input}
                placeholder="Cities, Regions, Activities"
                inputProps={{ 'aria-label': 'search' }}
                value={localSearchValue}
                onChange={handleChange}
              />
              <Divider className={classes.divider} orientation="vertical" />
              {hideMap === true ? null : (
                <>
                  <Tooltip
                    title={`View the results on a map`}
                    aria-label={`View the results on a map`}
                    placement={`top`}
                  >
                    <IconButton
                      color="secondary"
                      className={classes.iconButton}
                      aria-label="view on map"
                      onClick={() => history.push('/countries')}
                    >
                      <MapIcon />
                    </IconButton>
                  </Tooltip>
                  <Divider className={classes.divider} orientation="vertical" />
                </>
              )}
              <Tooltip title={`Plan a trip`} aria-label={`Plan a trip`} placement={`top`}>
                <IconButton
                  style={{ color: 'green' }}
                  className={classes.iconButton}
                  aria-label="plan a trip"
                  onClick={() => history.push('/saved/trips')}
                >
                  <BeachIcon />
                </IconButton>
              </Tooltip>
            </div>
            {localSearchValue !== '' || focused ? (
              <List dense>
                <ListItem style={{ justifyContent: 'center' }}>
                  <Button
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    onClick={() => resetSearchValue()}
                  >
                    Close Search
                  </Button>
                </ListItem>
                {filteredAttributes.length > 0 ? (
                  <>
                    {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                    <ListItem style={{ justifyContent: 'space-between' }}>
                      <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                        Filters
                      </Typography>
                      <Button
                        color={'primary'}
                        onClick={() => {
                          event(undefined, 'show_filters', { filter_set: 'attributes', value: !showAllAttributes });
                          setShowAllAttributes(!showAllAttributes);
                        }}
                      >
                        {showAllAttributes ? 'Hide' : 'Show All'}
                      </Button>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {filteredAttributes.map((attribute) => (
                  <ListItem
                    key={attribute}
                    onClick={() => {
                      event(undefined, 'search', { search_term: attribute });
                      resetSearchValue();
                      updateSelectedAddAction(attribute);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={`${attribute}`} src={`https://travel-atlas.com/image/icons/${attribute}.svg`} />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${toTitleCase(attribute.replace(/-/gi, ' '))}`}
                      secondary={`Show ${toTitleCase(attribute.replace(/-/gi, ' '))} cities`}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        edge="end"
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        onClick={() => {
                          event(undefined, 'search', { search_term: attribute });
                          resetSearchValue();
                          updateSelectedAddAction(attribute);
                        }}
                      >
                        Filter
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {filteredCities.length > 0 ? (
                  <>
                    <ListItem>
                      <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                        Cities
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {filteredCities.map((city) => (
                  <ListItem key={city.name} onClick={() => history.push(city.url)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={`${toTitleCase(city.name)}`}
                        src={getCoverImage(city.name, 240)}
                        style={{
                          boxShadow: visitedSet.has(city.name)
                            ? '0px 4px 2px -2px rgba(155,0,0,0.8), 0px 2px 2px 0px rgba(155,0,0,0.56), 0px 2px 6px 0px rgba(155,0,0,0.48)'
                            : wantToGoSet.has(city.name)
                            ? '0px 4px 2px -2px rgba(0,155,0,0.8), 0px 2px 2px 0px rgba(0,155,0,0.56), 0px 2px 6px 0px rgba(0,155,0,0.48)'
                            : 'inherit'
                        }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${toTitleCase(city.name)}`}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        edge="end"
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        onClick={() => {
                          event(
                            {
                              category: 'CityGrid',
                              action: 'Clicked Search Link',
                              label: city.name
                            },
                            'view_item',
                            {
                              items: city.name
                            }
                          );
                          history.push(city.url);
                        }}
                      >
                        View
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {Object.keys(filteredContinents).length > 0 ? (
                  <>
                    <ListItem>
                      <ListItem style={{ justifyContent: 'space-between' }}>
                        <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                          Continents
                        </Typography>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            event(undefined, 'show_filters', { filter_set: 'continents', value: !showAllRegions });
                            setShowAllRegions(!showAllRegions);
                          }}
                        >
                          {showAllRegions ? 'Hide' : 'Show All'}
                        </Button>
                      </ListItem>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {Object.entries(filteredContinents).map(([code, continent]) => (
                  <ListItem
                    key={code}
                    onClick={() => {
                      event(undefined, 'search', { search_term: code.toLowerCase() });
                      resetSearchValue();
                      updateContinents([...selectedContinents, code.toLowerCase()]);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={`${continent}`} src={`/images/continents/${continent.toLowerCase()}.svg`} />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${continent}`}
                      secondary={`Show cities in ${continent}`}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        edge="end"
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        onClick={() => {
                          event(undefined, 'search', { search_term: code.toLowerCase() });
                          resetSearchValue();
                          updateContinents([...selectedContinents, code.toLowerCase()]);
                        }}
                      >
                        Filter
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {Object.keys(filteredCountries).length > 0 ? (
                  <>
                    <ListItem style={{ justifyContent: 'space-between' }}>
                      <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                        Countries
                      </Typography>
                      <Button
                        color={'primary'}
                        onClick={() => {
                          event(undefined, 'show_filters', { filter_set: 'countries', value: !showAllRegions });
                          setShowAllRegions(!showAllRegions);
                        }}
                      >
                        {showAllRegions ? 'Hide' : 'Show All'}
                      </Button>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {Object.entries(filteredCountries).map(([code, country]) => (
                  <ListItem
                    key={code}
                    onClick={() => {
                      event(undefined, 'search', { search_term: code.toLowerCase() });
                      resetSearchValue();
                      updateCountries([...selectedCountries, code.toLowerCase()]);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={`${country.name}`}
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code.toUpperCase()}.svg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${country.name}`}
                      secondary={`Show cities in ${country.name}`}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        edge="end"
                        onClick={() => {
                          event(undefined, 'search', { search_term: code.toLowerCase() });
                          resetSearchValue();
                          updateCountries([...selectedCountries, code.toLowerCase()]);
                        }}
                      >
                        Filter
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {filteredWeather.length > 0 ? (
                  <>
                    <ListItem>
                      <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                        Weather
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {filteredWeather.map((weather) => (
                  <ListItem
                    key={weather}
                    onClick={() => {
                      let selectedWeather = selectedClimate.weather;
                      selectedWeather.push(weather);
                      event(undefined, 'search', { search_term: weather });
                      resetSearchValue();
                      updateClimate({
                        weather: selectedWeather,
                        months: selectedClimate.months
                      });
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={`${toTitleCase(weather)}`}
                        src={`https://travel-atlas.com/image/icons/${weather}.svg`}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${toTitleCase(weather)}`}
                      secondary={'Combine with months to filter'}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        edge="end"
                        onClick={() => {
                          let selectedWeather = selectedClimate.weather;
                          selectedWeather.push(weather);
                          event(undefined, 'search', { search_term: weather });
                          resetSearchValue();
                          updateClimate({
                            weather: selectedWeather,
                            months: selectedClimate.months
                          });
                        }}
                      >
                        Filter
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {filteredMonths.length > 0 ? (
                  <>
                    <ListItem>
                      <Typography variant="h6" style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                        Months
                      </Typography>
                    </ListItem>
                    <Divider />
                  </>
                ) : null}
                {filteredMonths.map((month) => (
                  <ListItem
                    key={month}
                    onClick={() => {
                      let selectedMonths = selectedClimate.months;
                      selectedMonths.push(month);
                      event(undefined, 'search', { search_term: month });
                      resetSearchValue();
                      updateClimate({
                        weather: selectedClimate.weather,
                        months: selectedMonths
                      });
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={`${toTitleCase(month)}`} src={`https://travel-atlas.com/image/icons/${month}.svg`} />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`${toTitleCase(month)}`}
                      secondary={'Combine with weather to filter'}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                        edge="end"
                        onClick={() => {
                          let selectedMonths = selectedClimate.months;
                          selectedMonths.push(month);
                          event(undefined, 'search', { search_term: month });
                          resetSearchValue();
                          updateClimate({
                            weather: selectedClimate.weather,
                            months: selectedMonths
                          });
                        }}
                      >
                        Filter
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
                {filteredCities.length === 0 &&
                filteredMonths.length === 0 &&
                filteredWeather.length === 0 &&
                Object.keys(filteredCountries).length === 0 &&
                Object.keys(filteredContinents).length === 0 ? (
                  <ListItem>
                    <ListItemText
                      style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                      primary={`No results for this search`}
                      secondary={'Try searching for places or activities'}
                    />
                  </ListItem>
                ) : null}
              </List>
            ) : null}
          </Paper>
        </Grid>
      </Container>
    </>
  );
}

export default IntelligentSearch;
