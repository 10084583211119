const visitedReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_VISITED':
      return [...state, action.payload];
    case 'REMOVE_VISITED':
      return state.filter((val) => val !== action.payload);
    case 'UPDATE_VISITED':
      return [...action.payload];
    default:
      return state;
  }
};

export default visitedReducer;
