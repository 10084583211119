const tripsReducer = (state = {}, action) => {
  let oldState;
  switch (action.type) {
    case 'PUT_TRIP':
      oldState = state;
      oldState[action.payload.index] = action.payload.trip;
      return [...oldState];
    case 'REMOVE_FROM_TRIP':
      oldState = state;
      const tripObject = JSON.parse(action.payload.trip.tripObject);
      tripObject.cities.splice(action.payload.position, 1);
      action.payload.trip.tripObject = JSON.stringify(tripObject);
      oldState[action.payload.position] = action.payload.trip;
      return [...oldState];
    case 'UPDATE_TRIPS':
      return [...action.payload];
    default:
      return state;
  }
};

export default tripsReducer;
