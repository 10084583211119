import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoldIcon from 'mdi-react/CheckBoldIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { event } from '../../util/tracking';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { colourScheme } from '../../util/colourSchemes';
import CloseIcon from 'mdi-react/CloseIcon';
import Typography from '@material-ui/core/Typography';
import { arrayToSentence, toTitleCase } from '../../util/textFormatting';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import BeachIcon from 'mdi-react/BeachIcon';
import Dialog from '@material-ui/core/Dialog';
import CreateTrip from './CreateTrip';
import { ListItemIcon } from '@material-ui/core';
import { getTripCoverImage } from '../../util/images';

const TEXT_TRANSLATION_AMOUNT = 'translate(-50%, -50%)';
const TRANSITION_AMOUNT = 'all 0.3s ease-in-out 0s';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    transition: 'opacity 700ms linear',
    transitionDelay: '100ms'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  },
  content: {
    position: 'relative'
  },
  contentOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.7))',
    paddingTop: '56.25%',
    width: '100%',
    opacity: '0.7',
    cursor: 'pointer',
    '-webkit-transition': 'all 0.4s ease-in-out 0s',
    '-moz-transition': 'all 0.4s ease-in-out',
    transition: 'all 0.4s ease-in-out 0s',
    transitionDelay: '0.1s',
    '&:hover': {
      opacity: '1'
    }
  },
  contentInner: {
    position: 'absolute',
    textAlign: 'center',
    paddingLeft: '1em',
    paddingRight: '1em',
    width: '100%',
    top: '90%',
    left: '50%',
    opacity: '1',
    '-webkit-transform': TEXT_TRANSLATION_AMOUNT,
    '-moz-transform': TEXT_TRANSLATION_AMOUNT,
    transform: TEXT_TRANSLATION_AMOUNT,
    '-webkit-transition': TRANSITION_AMOUNT,
    '-moz-transition': TRANSITION_AMOUNT,
    transition: 'all 0.3s ease-in-out 0s',
    '& h1': {
      color: '#fff',
      fontWeight: '500',
      letterSpacing: '0.3em',
      marginBottom: '0.5em',
      textTransform: 'uppercase'
    },
    '& p': {
      color: '#fff',
      fontSize: '0.8em'
    }
  }
}));

// TODO:: break this up into separate functions
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function ExpandableCityCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [createTripDialogOpen, setCreateTripDialogOpen] = React.useState(false);

  const handleCloseDialog = () => {
    event(undefined, 'close_trip_dialog', {});
    setCreateTripDialogOpen(false);
  };

  const {
    history,
    name,
    src,
    visitedSet,
    visited,
    wantToGoSet,
    wantToGo,
    addToVisited,
    addToWantToGo,
    removeFromVisited,
    removeFromWantToGo,
    trips,
    user,
    globalTheme,
    changeTrip,
    onClose,
    isVisible,
    elevation,
    climate,
    selected,
    createTrip
  } = props;

  const handleExpandClick = () => {
    event(
      {
        category: 'ExpandableCityCard',
        action: 'Expanded Card',
        label: name
      },
      'expanded_card',
      {
        destination: name
      }
    );
    setExpanded(!expanded);
  };

  const viewCity = () => {
    event(
      {
        category: 'CityGrid',
        action: 'Clicked CityGrid link',
        label: name
      },
      'view_item',
      {
        items: name,
        destination: name
      }
    );
    history.push(`/city/${name}`);
  };

  return (
    <Tooltip
      title={
        <>
          <Typography variant="h6">{toTitleCase(name)}</Typography>
          <Divider style={{ background: 'white' }} />
          <br />
          {visitedSet.has(name) && <Typography variant="body2">You've been here before</Typography>}
          {wantToGoSet.has(name) && <Typography variant="body2">You want to go here</Typography>}
          {climate && climate.weather.length > 0 && climate.months.length > 0 ? (
            <div>
              <Typography variant="body2">
                {toTitleCase(name)} is {arrayToSentence(climate.weather)} in{' '}
                {arrayToSentence(climate.months.map((month) => toTitleCase(month)))}
              </Typography>
            </div>
          ) : null}
          {selected && selected.length > 0 ? (
            <div>
              <br />
              <Typography variant="body1">Matched with:</Typography>
              <Divider style={{ background: 'white' }} />
              <br />
              {selected.map((selected) => (
                <Typography key={selected} variant="body2">
                  {toTitleCase(selected.replace(/-/gi, ' '))}
                </Typography>
              ))}
            </div>
          ) : null}
        </>
      }
      placement={`top`}
      aria-label={`Extra information`}
    >
      <Card
        className={classes.content}
        elevation={elevation ? 2 : 0}
        style={{
          backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
          background: colourScheme[globalTheme.colourScheme].cardColour
        }}
      >
        <CardMedia
          className={classes.media}
          image={isVisible ? src : 'https://travel-atlas.com/image/city.webp'}
          alt={name}
          style={{ opacity: isVisible ? 1 : 0.25 }}
          onClick={viewCity}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={classes.contentOverlay} onClick={viewCity}>
          <div className={classes.contentInner}>
            <Typography component="h1">{name}</Typography>
          </div>
        </div>
        <CardActions disableSpacing>
          <Tooltip title={'Learn about this city'} placement={'bottom'} aria-label={`Learn about this city`}>
            <IconButton aria-label="view this city" style={{ color: 'grey' }} onClick={viewCity}>
              <EyeIcon />
            </IconButton>
          </Tooltip>
          {isVisible ? (
            <>
              <Tooltip
                title={'Add this city to your want to go list'}
                placement={'bottom'}
                aria-label={`Add this city to your want to go list`}
              >
                <IconButton
                  aria-label="add to want to go"
                  style={{ color: wantToGoSet.has(name) ? 'green' : 'grey' }}
                  onClick={() =>
                    wantToGoSet.has(name)
                      ? removeFromWantToGo(user, wantToGo, name)
                      : addToWantToGo(user, wantToGo, name)
                  }
                >
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={'Add this city to your already visited list'}
                placement={'bottom'}
                aria-label={`Add this city to your already visited list`}
              >
                <IconButton
                  aria-label="add to visited"
                  style={{ color: visitedSet.has(name) ? 'red' : 'grey' }}
                  onClick={() =>
                    visitedSet.has(name) ? removeFromVisited(user, visited, name) : addToVisited(user, visited, name)
                  }
                >
                  <CheckBoldIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={'Add this city to one of your planned trips'}
                placement={'bottom'}
                aria-label={`Add this city to one of your planned trips`}
              >
                <IconButton
                  style={{
                    color: colourScheme[globalTheme.colourScheme].primaryText
                  }}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Tooltip>
              {onClose ? (
                <IconButton aria-label="close card" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </>
          ) : null}
        </CardActions>
        {isVisible ? (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List>
              {Object.keys(trips).map((tripKey, index) => (
                <ListItem
                  button
                  style={{
                    cursor: 'pointer',
                    maxWidth: 300
                  }}
                  key={index}
                  onClick={async () => {
                    const trip = trips[tripKey];
                    let tripObject = JSON.parse(trips[tripKey].tripObject);
                    if (tripObject['cities'] === undefined) {
                      tripObject['cities'] = [];
                    }
                    tripObject['cities'].push(name);
                    trip.tripObject = JSON.stringify(tripObject);
                    event(
                      {
                        category: 'Map',
                        action: 'add_to_trip',
                        label: name
                      },
                      'add_to_trip',
                      { city: name, trip_name: trip.name }
                    );
                    await changeTrip(user, trip, index);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`${trips[tripKey].name}`}
                      src={getTripCoverImage(trips[tripKey], tripKey, 240)}
                      style={
                        JSON.parse(trips[tripKey].tripObject).cities.includes(name)
                          ? {
                              borderStyle: 'solid',
                              borderWidth: '3px',
                              borderColor: '#2196f3'
                            }
                          : {}
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                    primary={trips[tripKey].name}
                    secondary={
                      JSON.parse(trips[tripKey].tripObject).cities.includes(name) ? `Has this city` : `Add to this trip`
                    }
                  />
                  <ListItemIcon>
                    {JSON.parse(trips[tripKey].tripObject).cities.includes(name) ? (
                      <CheckBoldIcon style={{ color: 'green' }} />
                    ) : null}
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
            <Grid container justify="center" alignItems="center" style={{ margin: '8px' }}>
              <Button
                variant={'contained'}
                disableElevation
                color={'primary'}
                onClick={() => {
                  event(undefined, 'open_new_trip_dialog', { tool: 'city_card' });
                  setCreateTripDialogOpen(true);
                }}
              >
                <BeachIcon style={{ marginRight: '6px' }} />
                Create Trip
              </Button>
              <Button
                variant={'contained'}
                disableElevation
                color={'secondary'}
                onClick={() => history.push('/saved/trips')}
                style={{ marginRight: '6px', marginLeft: '6px' }}
              >
                View Trips
              </Button>
              <Dialog
                open={createTripDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title-create-trip"
              >
                <CreateTrip
                  globalTheme={globalTheme}
                  handleClose={handleCloseDialog}
                  firstCityName={name}
                  user={user}
                  trips={trips}
                  createTrip={createTrip}
                  forwardOnCreate={false}
                  history={history}
                  page={'city_card'}
                />
              </Dialog>
            </Grid>
          </Collapse>
        ) : null}
      </Card>
    </Tooltip>
  );
}
