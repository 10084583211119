import { combineReducers } from 'redux';
import wantToGo from './wantToGoReducer';
import attributes from './attributesReducer';
import visited from './visitedReducer';
import search from './searchReducer';
import selected from './selectedReducer';
import trips from './tripsReducer';
import user from './userReducer';
import climate from './climateReducer';
import theme from './themeReducer';
import toolbarState from './toolbarStateReducer';
import searchValue from './searchValueReducer';
import privacy from './privacyReducer';
import selectedContinents from './selectedContinentsReducer';
import selectedCountries from './selectedCountriesReducer';

export default combineReducers({
  wantToGo,
  attributes,
  visited,
  search,
  selected,
  trips,
  user,
  climate,
  theme,
  toolbarState,
  searchValue,
  privacy,
  selectedCountries,
  selectedContinents
});
