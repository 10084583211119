const searchReducer = (state = {}, action) => {
  let oldState;
  switch (action.type) {
    case 'ADD_SEARCH':
      oldState = state;
      oldState[action.payload.name] = action.payload.selected;
      return { ...oldState };
    case 'REMOVE_SEARCH':
      oldState = state;
      delete oldState[action.payload];
      return { ...oldState };
    case 'UPDATE_SEARCH':
      return { ...action.payload };
    default:
      return state;
  }
};

export default searchReducer;
