/**
 *
 * @param {Set<any>} set
 * @param {Set<any>} set
 * @returns {boolean}
 */
export function isSuperset(set, subset) {
  for (let elem of subset) {
    if (!set.has(elem)) {
      return false;
    }
  }
  return true;
}

/**
 *
 * @param {Set<any>} setA
 * @param {Set<any>} setB
 * @returns {Set<any>}
 */
export function union(setA, setB) {
  let _union = new Set(setA);
  for (let elem of setB) {
    _union.add(elem);
  }
  return _union;
}

/**
 *
 * @param {Set<any>} setA
 * @param {Set<any>} setB
 * @returns {Set<any>}
 */
export function intersection(setA, setB) {
  let _intersection = new Set();
  for (let elem of setB) {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  }
  return _intersection;
}

/**
 *
 * @param {Set<any>} setA
 * @param {Set<any>} setB
 * @returns {Set<any>}
 */
export function symmetricDifference(setA, setB) {
  let _difference = new Set(setA);
  for (let elem of setB) {
    if (_difference.has(elem)) {
      _difference.delete(elem);
    } else {
      _difference.add(elem);
    }
  }
  return _difference;
}

/**
 *
 * @param {Set<any>} setA
 * @param {Set<any>} setB
 * @returns {Set<any>}
 */
export function difference(setA, setB) {
  let _difference = new Set(setA);
  for (let elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}
