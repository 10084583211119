import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { event } from '../../util/tracking';
import { Paper } from '@material-ui/core';
import CreateTrip from '../Map/CreateTrip';
import { isMobile } from 'react-device-detect';
import { colourScheme } from '../../util/colourSchemes';

const TITLE_POSITION_TRANSITION = '1s ease-in-out';
const CENTER_TRANSFORM = 'translate(-50%,-50%)';

const useStyles = makeStyles((theme) => ({
  createTripButtonClosed: {
    opacity: '100%',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION,
    transitionDelay: '0.6s'
  },
  createTripButtonOpen: {
    opacity: '0%',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION
  },
  parallaxHeightClosed: {
    height: '60vh',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION,
    transitionDelay: '0.6s'
  },
  parallaxHeightOpen: {
    height: 'calc(100vh - 64px)',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION
  },
  tripFormClosed: {
    textAlign: 'center',
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: CENTER_TRANSFORM,
    overflow: 'hidden',
    height: '0px',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION
  },
  tripFormOpen: {
    minWidth: isMobile ? '95vw' : '40vw',
    textAlign: 'center',
    position: 'absolute',
    top: isMobile ? '50%' : '57%',
    left: '50%',
    transform: CENTER_TRANSFORM,
    overflow: 'hidden',
    height: isMobile ? '90vh' : '70vh',
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION,
    transitionDelay: '0.6s'
  },
  titleTextPositionClosed: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: CENTER_TRANSFORM,
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION,
    transitionDelay: '0.6s'
  },
  titleTextPositionOpen: {
    textAlign: 'center',
    position: 'absolute',
    top: isMobile ? '10%' : '15%',
    left: '50%',
    transform: CENTER_TRANSFORM,
    webkitTransition: TITLE_POSITION_TRANSITION,
    mozTransition: TITLE_POSITION_TRANSITION,
    oTransition: TITLE_POSITION_TRANSITION,
    transition: TITLE_POSITION_TRANSITION
  },
  titleTextBackground: {
    padding: '18px'
  },
  titleTextStyle: {
    color: 'white',
    marginBottom: '12px',
    fontFamily: 'Oswald',
    textShadow: '4px 4px 0px rgba(0,0,0,0.2)'
  }
}));

function TripPrompt(props) {
  const { globalTheme, user, trips, createTrip, history } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div className={open ? classes.parallaxHeightOpen : classes.parallaxHeightClosed}>
      <div className={open ? classes.titleTextPositionOpen : classes.titleTextPositionClosed}>
        <div className={classes.titleTextBackground}>
          <Typography
            className={classes.titleTextStyle}
            variant={isMobile && open ? 'h5' : isMobile ? 'h4' : 'h3'}
            component="h1"
          >
            PLAN YOUR PERFECT TRIP
          </Typography>
        </div>
        <Button
          className={open ? classes.createTripButtonOpen : classes.createTripButtonClosed}
          style={{
            backgroundColor: colourScheme[globalTheme.colourScheme].primaryButton,
            color: colourScheme[globalTheme.colourScheme].primaryButtonTextColour
          }}
          variant="contained"
          disableElevation
          onClick={() => {
            event(undefined, 'open_new_trip_dialog', { tool: 'landing' });
            setOpen(true);
          }}
        >
          Create a Trip
        </Button>
      </div>
      <Paper
        style={{
          borderRadius: '24px',
          backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
          background: colourScheme[globalTheme.colourScheme].cardColour
        }}
        className={open ? classes.tripFormOpen : classes.tripFormClosed}
      >
        <CreateTrip
          globalTheme={globalTheme}
          handleClose={() => setOpen(false)}
          user={user}
          trips={trips}
          createTrip={createTrip}
          forwardOnCreate={true}
          history={history}
          page={'landing'}
        />
      </Paper>
    </div>
  );
}

export default TripPrompt;
