export const toTitleCase = (sentence) => {
  if (sentence === "st john's") {
    return "St John's";
  }
  return sentence.replace(/\b[a-zA-Z]/g, (match) => match.toUpperCase());
};

export const arrayToSentence = (arr) => {
  return arr.slice(0, -2).join(', ') + (arr.slice(0, -2).length ? ', ' : '') + arr.slice(-2).join(' and ');
};

export const structuredDataTravelDestination = (name, description, imgPath) => {
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'TouristDestination',
    name: name,
    description: description,
    url: window.location.href,
    touristType: {
      '@type': 'Audience',
      audienceType: ['Backpacking', 'City Hopping', 'Euro Trip', 'Hostelling', 'Young Adults', 'Adventure']
    },
    includesAttraction: [
      {
        '@type': ['City', 'TouristAttraction'],
        name: name,
        image: imgPath
      }
    ]
  });
};
