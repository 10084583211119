import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colourScheme } from '../../util/colourSchemes';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import GoogleButton from 'react-google-button';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { toTitleCase } from '../../util/textFormatting';
import { getCoverImage } from '../../util/images';
import Autocomplete from '@material-ui/lab/Autocomplete';
import cities from '../../data/dataSheet.json';
import { event } from '../../util/tracking';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import {
  Chip,
  DialogContentText,
  ListItem,
  ListItemIcon,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper
} from '@material-ui/core';
import CloseIcon from 'mdi-react/CloseIcon';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import Auth from '@aws-amplify/auth';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ArrowRightAlt } from '@material-ui/icons';
import List from '@material-ui/core/List';
import FlightIcon from 'mdi-react/FlightIcon';
import { isMobile } from 'react-device-detect';
import CalendarIcon from 'mdi-react/CalendarIcon';
import UserCardDetailsIcon from 'mdi-react/UserCardDetailsIcon';
import { Link } from 'react-router-dom';
import { isValidCity } from '../../util/cityData';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function countryToFlag(cityName) {
  return cities[cityName] !== undefined
    ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${cities[cityName].country.toUpperCase()}.svg`
    : '';
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function CreateTrip(props) {
  const classes = useStyles();
  const { firstCityName, globalTheme, handleClose, forwardOnCreate, history, user, page } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [tripName, setTripName] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [initialCities, setInitialCities] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [showAddField, setShowAddField] = useState(false);
  const [preventDoubleClick, setPreventDoubleClick] = useState(false);

  const isStepOptional = (step) => {
    return (user ? [1] : [0, 2]).includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === steps.length - 1) {
      setPreventDoubleClick(true);
      await handleCreateNewTrip();
      setPreventDoubleClick(false);
      return;
    }
    event(undefined, 'next_step_create_trip', { step: activeStep });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setTripName('');
      setInitialCities([]);
      handleClose();
      return;
    }
    event(undefined, 'previous_step_create_trip', { step: activeStep });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    event(undefined, 'skip_step_create_trip', { step: activeStep });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    let basicSteps = ['Give your trip a name', 'Add destinations to your trip', 'Review your Trip'];
    return !user ? ['Create an Account', ...basicSteps] : basicSteps;
  }
  const steps = getSteps();

  useEffect(() => {
    if (firstCityName) {
      setInitialCities([firstCityName]);
    }
  }, [firstCityName]);

  async function handleCreateNewTrip() {
    const { createTrip, user, trips } = props;
    if (tripName === '') {
      setErrorText('Every trip needs a trip name.');
      setActiveStep(user ? 0 : 1);
      return;
    }
    await createTrip(user, trips, tripName, { cities: initialCities });
    event(
      {
        category: 'Trip Collaborator',
        action: 'Created Trip',
        label: tripName
      },
      'create_trip',
      { trip_name: tripName }
    );
    setTripName('');
    setInitialCities([]);
    handleClose();
    if (forwardOnCreate) {
      history.push('/saved/trips');
    }
  }

  const handleTextUpdate = ({ target }) => {
    setErrorText('');
    setTripName(target.value);
  };

  const handleLogin = async (provider) => {
    event(
      {
        category: 'Authentication',
        action: 'Clicked Feature Login',
        label: 'Google'
      },
      'login',
      { method: 'Google' }
    );
    await Auth.federatedSignIn({ provider });
  };

  function getStepContent(step) {
    const accountStep = (
      <Grid container alignItems="center" justify="center" direction="column">
        <Typography
          style={{
            color: colourScheme[globalTheme.colourScheme].primaryText,
            fontFamily: 'Cardo'
          }}
        >
          Sign-in with Google first to save your trips across devices and sessions.
        </Typography>
        <Typography
          style={{
            color: colourScheme[globalTheme.colourScheme].primaryText,
            fontFamily: 'Cardo'
          }}
        >
          You can even plan a trip live with with your friends!
        </Typography>
        <GoogleButton
          style={{
            marginTop: '12px',
            boxShadow:
              '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 4px 7px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
          }}
          type={globalTheme.colourScheme === 'dark' ? 'dark' : 'light'}
          onClick={() => handleLogin('Google')}
        />
      </Grid>
    );

    const addCitiesStep = (
      <div style={{ minHeight: '100px' }}>
        <Grid spacing={1} justify="center" alignItems="center" container direction="column">
          {initialCities.map((city, index) => (
            <Grid xs={12} item key={index}>
              <Chip
                size={'small'}
                avatar={<Avatar alt={`${toTitleCase(city)}`} src={getCoverImage(city, 240)} />}
                label={toTitleCase(city)}
                color="primary"
                onDelete={() => {
                  if (initialCities.length === 1) {
                    setInitialCities([]);
                  } else {
                    const newCities = initialCities;
                    newCities.splice(index, 1);
                    setInitialCities([...newCities]);
                  }
                }}
                deleteIcon={<CloseIcon />}
              />
            </Grid>
          ))}
          <Grid item>
            {!showAddField ? (
              <Grid justify="center" alignItems="center" container>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    event(undefined, 'open_add_city_trip_dialog', {});
                    setShowAddField(true);
                  }}
                >
                  <PlusCircleIcon style={{ marginRight: '12px' }} />
                  Add City
                </Button>
              </Grid>
            ) : null}
            {showAddField ? (
              <>
                <Autocomplete
                  style={{ minWidth: '240px' }}
                  fullWidth
                  value={autocompleteValue}
                  disablePortal
                  id="combo-box-demo"
                  options={Object.keys(cities).sort()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant={'outlined'}
                      label="City Name"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                      }}
                    />
                  )}
                  clearOnEscape
                  selectOnFocus
                  clearOnBlur
                  freeSolo
                  handleHomeEndKeys
                  autoHighlight
                  renderOption={(event, option) => (
                    <li>
                      <span style={{ display: 'flex' }}>
                        <Avatar
                          style={{ height: '18px', width: '18px', marginRight: '8px' }}
                          src={countryToFlag(event)}
                        />
                        {toTitleCase(event)}
                      </span>
                    </li>
                  )}
                  onChange={(e, newValue) => {
                    if (typeof newValue === 'string' && isValidCity(newValue)) {
                      setInitialCities([...initialCities, newValue]);
                      event(undefined, 'add_city_trip_dialog', { name: newValue });
                      setAutocompleteValue('');
                      setShowAddField(false);
                    } else {
                      setAutocompleteValue(newValue);
                    }
                  }}
                />
                <Alert severity="info">
                  City you want missing? <Link to="/give-feedback">Request it</Link> and we'll add it ASAP.
                </Alert>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    );

    const tripNameStep = (
      <>
        <TextField
          label="Trip Name"
          type="text"
          value={tripName}
          onChange={handleTextUpdate}
          fullWidth
          variant={'outlined'}
        />
      </>
    );

    const reviewTripStep = (
      <Grid justify="center" alignItems="center" container direction={'column'}>
        <Grid item>
          <Typography style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
            Check your trip is the way you like. You can always change it later.
          </Typography>
        </Grid>
        {!errorText && !isMobile ? (
          <>
            <Grid item>
              <Typography
                style={{
                  marginTop: '18px',
                  color: colourScheme[globalTheme.colourScheme].primaryText,
                  fontFamily: 'Cardo'
                }}
                variant={'h6'}
              >
                Click Create Trip to:
              </Typography>
            </Grid>
            <Grid item style={{ height: '100%', overflowY: 'scroll' }}>
              <List style={{ width: '100%' }}>
                <ListItem>
                  <ListItemIcon>
                    <FlightIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                      variant={'body2'}
                    >
                      Choose from the best flights, trains, and bus routes for your trip
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CalendarIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                      variant={'body2'}
                    >
                      Plan your activities day-by-day with suggestions
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <UserCardDetailsIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                      variant={'body2'}
                    >
                      Customize your user profile to see visa requirements and packing advice for your trip
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </>
        ) : null}
      </Grid>
    );

    switch (step) {
      case 0:
        return !user ? accountStep : tripNameStep;
      case 1:
        return !user ? tripNameStep : addCitiesStep;
      case 2:
        return !user ? addCitiesStep : reviewTripStep;
      default:
        return reviewTripStep;
    }
  }

  return (
    <>
      <DialogTitle
        id="form-dialog-title-create-trip"
        style={{
          backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
          background: colourScheme[globalTheme.colourScheme].cardColour,
          textAlign: 'center'
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          style={{
            color: colourScheme[globalTheme.colourScheme].primaryText,
            fontFamily: 'Oswald'
          }}
        >
          {tripName ? tripName : 'Create a New Trip'}
        </Typography>
        {(user && activeStep === 2) || (!user && activeStep === 3) ? (
          <Grid spacing={1} justify="center" container>
            {initialCities.map((city, index) => (
              <Grid item key={index}>
                <Chip
                  avatar={<Avatar alt={`${toTitleCase(city)}`} src={getCoverImage(city, 240)} />}
                  label={toTitleCase(city)}
                  color="primary"
                />
                {index < initialCities.length - 1 ? <ArrowRightAlt style={{ paddingTop: '12px' }} /> : null}
              </Grid>
            ))}
          </Grid>
        ) : null}
      </DialogTitle>
      <DialogContentText
        style={{
          backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
          background: colourScheme[globalTheme.colourScheme].cardColour,
          textAlign: 'center'
        }}
      >
        {errorText !== '' ? (
          <Alert severity="error" style={{ marginBottom: '12px' }}>
            {errorText}
          </Alert>
        ) : null}
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{
              backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
              background: colourScheme[globalTheme.colourScheme].cardColour
            }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant="caption">Optional</Typography>;
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    {...labelProps}
                    style={{
                      backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
                      background: colourScheme[globalTheme.colourScheme].cardColour
                    }}
                  >
                    <Typography
                      style={{
                        color: colourScheme[globalTheme.colourScheme].primaryText,
                        fontFamily: 'Cardo'
                      }}
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                  <StepContent
                    style={{
                      backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
                      background: colourScheme[globalTheme.colourScheme].cardColour
                    }}
                  >
                    <Divider
                      style={{ marginBottom: '16px', background: colourScheme[globalTheme.colourScheme].primaryText }}
                    />
                    {getStepContent(activeStep)}
                    <Divider
                      style={{ marginTop: '16px', background: colourScheme[globalTheme.colourScheme].primaryText }}
                    />
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div>
                <div>
                  <Button
                    onClick={handleBack}
                    className={classes.button}
                    style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                  >
                    {activeStep === 0 ? 'Cancel' : 'Back'}
                  </Button>
                  {isStepOptional(activeStep) && (
                    <Button variant="contained" color="primary" onClick={handleSkip} className={classes.button}>
                      Skip
                    </Button>
                  )}

                  <Button
                    disabled={preventDoubleClick}
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Create Trip' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContentText>
    </>
  );
}

export default CreateTrip;
