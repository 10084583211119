import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import { store, persistor as PersistedStore } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { init } from './util/tracking';
import { BrowserRouter } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import awsConfig from './setupAwsConfig';
import SentryLogging from './util/errorLogging';
import fromEntries from 'object.fromentries';
import 'url-search-params-polyfill';
import { getCLS, getFID, getLCP } from 'web-vitals';
import { event } from './util/tracking';

/** Begin polyfills */
if (!Object.fromEntries) {
  fromEntries.shim();
}
require('es7-object-polyfill');
/** End polyfills */

init();

new SentryLogging();

Amplify.configure(awsConfig);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={PersistedStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToGoogleAnalytics({ name, delta, value, id }) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  event(null, name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
}

getCLS(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);
