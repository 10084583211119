import { updateTripsAction } from '../../actions/trip/updateTripsAction';
import { putTripAction } from '../../actions/trip/putTripAction';
import { createTrip, createTripEditor, deleteTripEditor, updateTrip } from '../../graphql/mutations';
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { getTrip, getTripEditor, getUser } from '../../graphql/queries';
import { deSerializeUser, pullRemoteUserState, updateLocalUserState } from '../user/user';

export const updateLocalTripsState = async (dispatch, trips) => {
  dispatch(updateTripsAction(trips));
};

export const getTripForTripEditorId = async (id) => {
  try {
    const tripEditorItem = await GraphQLAPI.graphql(graphqlOperation(getTripEditor, { id: id }));
    const tripItem = await GraphQLAPI.graphql(
      graphqlOperation(getTrip, {
        id: tripEditorItem.data.getTripEditor.trip.id
      })
    );
    return tripItem.data.getTrip;
  } catch (error) {
    console.error('Failed to fetch trip for trip trip editor', error);
  }
};

/**
 * Resolve trips for which the user is the trip editor
 *
 * @param user - the user who edits the trips
 * @returns {Promise<unknown[]>}
 */
export const resolveUserTrips = async (user) => {
  if (user && user.trips && user.trips.items) {
    return await Promise.all(
      user.trips.items.map(
        await (async (item) => {
          return await getTripForTripEditorId(item.id);
        })
      )
    );
  }
  return [];
};

export const updateLocalTripsFromUser = async (dispatch, user) => {
  if (user !== null) {
    try {
      const resolvedTrips = await resolveUserTrips(user);
      await updateLocalTripsState(dispatch, resolvedTrips);
      return resolvedTrips;
    } catch (error) {
      console.error('Error updating local trips from user data', error);
    }
  }
  return [];
};

export const createNewTrip = async (dispatch, user, trips, tripName, tripObject = { cities: [] }) => {
  try {
    // Create new trip
    const newTrip = {
      name: tripName,
      tripObject: JSON.stringify(tripObject)
    };
    trips.push(newTrip);
    if (user !== null) {
      const resultTrip = await GraphQLAPI.graphql(graphqlOperation(createTrip, { input: newTrip }));
      // Use newly created trip and add the current user as an editor of that trip
      const newTripEditor = {
        tripEditorTripId: resultTrip.data.createTrip.id,
        tripEditorEditorId: user.id
      };
      await GraphQLAPI.graphql(graphqlOperation(createTripEditor, { input: newTripEditor }));
      // Get updated user and update local state from that
      const existingUser = await GraphQLAPI.graphql(graphqlOperation(getUser, { id: user.username }));
      user = deSerializeUser(existingUser.data.getUser);
    }
    await updateLocalUserState(dispatch, user, null, null, null, trips);
    await updateLocalTripsFromUser(dispatch, user);
  } catch (error) {
    console.error('Error Creating Trip', error);
  }
};

export const updateSingleTrip = async (dispatch, user, trip, index) => {
  try {
    dispatch(putTripAction({ index, trip }));
    if (user !== null) {
      await GraphQLAPI.graphql(
        graphqlOperation(updateTrip, {
          input: {
            id: trip.id,
            tripObject: trip.tripObject,
            _version: trip._version
          }
        })
      );
    }
  } catch (error) {
    console.error('Error Updating Trip', error);
  }
};

export const deleteUserFromTripGraph = async (dispatch, user, trips, tripIndex) => {
  // Only remove from local store because we have no login
  const tripToDeleteId = trips[tripIndex].id;
  trips.splice(tripIndex, 1);
  await updateLocalTripsState(dispatch, trips);

  if (user !== null) {
    for (const tripEditor of user.trips.items) {
      try {
        const tripEditorTrip = await getTripForTripEditorId(tripEditor.id);
        if (tripEditorTrip.id === tripToDeleteId) {
          await GraphQLAPI.graphql(
            graphqlOperation(deleteTripEditor, {
              input: { id: tripEditor.id }
            })
          );
          await pullRemoteUserState(dispatch, user, 'deleteUserFromTripGraph');
          return;
        }
      } catch (error) {
        console.error('Error removing trip from User', error);
      }
    }
  }
};
