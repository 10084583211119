const selectedReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_SELECTED':
      if (state.indexOf(action.payload) < 0) {
        return [...state, action.payload];
      }
      return state;
    case 'REMOVE_SELECTED':
      return state.filter((val) => val !== action.payload);
    case 'PUT_SELECTED':
      return [...action.payload];
    default:
      return state;
  }
};

export default selectedReducer;
