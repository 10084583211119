import { updateLocalUserState, updateRemoteUserState } from './user';
import { addToSet, removeFromSet } from '../util';

export const addToVisited = async (dispatch, user, visited, destination) => {
  visited = addToSet(visited, destination);
  if (user !== null) {
    user.visited = visited;
  }

  await updateLocalUserState(dispatch, user, visited);
  await updateRemoteUserState(dispatch, user);
};

export const removeFromVisited = async (dispatch, user, visited, destination) => {
  visited = removeFromSet(visited, destination);
  if (user !== null) {
    user.visited = visited;
  }
  await updateLocalUserState(dispatch, user, visited);
  await updateRemoteUserState(dispatch, user);
};
