import countries from '../data/countries.json';

/**
 * @type {string[]} - Complete array of attributes we use to filter in travel-atlas
 */
export const ATTRIBUTES = [
  'boating',
  'hiking',
  'surfing',
  'diving',
  'golf',
  'nightlife',
  'museums',
  'gambling',
  'temples-churches',
  'travel-during-coronavirus',
  'good-food',
  'street-food',
  'vegan-friendly',
  'cheap',
  'walkable',
  'has-uber',
  'speaks-english',
  'schengen',
  'near-parks',
  'yacht-access',
  'international-airport',
  'clean',
  'green-space',
  'history',
  'university-town',
  'small',
  'large',
  'hipster',
  'beaches',
  'family-friendly'
];
/**
 * @type {string[]} - Complete array of weather types we use to filter in travel-atlas
 */
export const WEATHER = ['cold', 'mild', 'warm'];
/**
 * @type {string[]} - Complete array of months we use to filter in travel-atlas
 */
export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

/**
 * Filters out objects that don't have given attributes
 * @param items
 * @param attributes
 * @returns {{}}
 */
export function filterByAttributes(items, attributes) {
  const filteredItems = {};
  Object.keys(items).forEach((key) => {
    const hasAttributes = items[key].attributes;
    if (hasAttributes) {
      for (const select of attributes) {
        if (items[key].attributes[select] === '0') {
          return;
        }
      }
    }
    filteredItems[key] = items[key];
  });
  return filteredItems;
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isIterable(obj) {
  // checks for null and undefined
  if (obj === null || obj === undefined) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

function objectIncludes(iter, el) {
  if (!isIterable(iter)) {
    return false;
  }
  return Object.values(iter).indexOf(el) > -1;
}

/**
 * Filters out climates that don't match the search parameters when both a climate and a set of months are provided
 *
 * @param cities
 * @param climate
 * @returns {{}|*}
 */
// TODO:: Refactor into multiple functions
// eslint-disable-next-line sonarjs/cognitive-complexity
export function filterByClimate(cities, climate) {
  const filteredItems = {};
  if (climate.weather.length > 1) {
    return {};
  }
  if (climate.weather.length && climate.months.length) {
    Object.keys(cities).forEach((key) => {
      if (cities[key]['WEATHER']) {
        if (objectIncludes(climate.weather, 'warm')) {
          let warmMonths = 0;
          for (const month of Object.values(climate.months)) {
            const lowTemp = cities[key]['WEATHER'][`low${capitalize(month)}`];
            const highTemp = cities[key]['WEATHER'][`high${capitalize(month)}`];
            if (lowTemp > 15 && highTemp > 25) {
              ++warmMonths;
            }
          }
          if (warmMonths === climate.months.length) {
            filteredItems[key] = cities[key];
          }
        }
        if (objectIncludes(climate.weather, 'mild')) {
          let mildMonths = 0;
          for (const month of climate.months) {
            const lowTemp = cities[key]['WEATHER'][`low${capitalize(month)}`];
            const highTemp = cities[key]['WEATHER'][`high${capitalize(month)}`];
            if (lowTemp > 5 && highTemp < 20) {
              ++mildMonths;
            }
          }
          if (mildMonths === climate.months.length) {
            filteredItems[key] = cities[key];
          }
        }
        if (objectIncludes(climate.weather, 'cold')) {
          let coldMonths = 0;
          for (const month of climate.months) {
            const lowTemp = cities[key]['WEATHER'][`low${capitalize(month)}`];
            const highTemp = cities[key]['WEATHER'][`high${capitalize(month)}`];
            if (lowTemp < 0 && highTemp < 10) {
              ++coldMonths;
            }
          }
          if (coldMonths === climate.months.length) {
            filteredItems[key] = cities[key];
          }
        }
      }
    });
  } else {
    return cities;
  }
  return filteredItems;
}

/**
 *
 * @param items
 * @param countryCode
 * @returns {object}
 */
export function filterByCountryCode(items, countryCode) {
  const filteredItems = {};
  Object.keys(items).forEach((key) => {
    if (items[key].country && items[key].country === countryCode) {
      filteredItems[key] = items[key];
    }
  });
  return filteredItems;
}

/**
 *
 * @param {object} cities
 * @param {object} climate
 * @param {array} selectedAttributes
 * @param {array} selectedCountries
 * @param {array} selectedContinents
 * @returns {{}|*}
 */
export function completeFilter(cities, climate, selectedAttributes, selectedCountries, selectedContinents) {
  let cleanedCities = {},
    key;
  for (key in cities) {
    if (key !== '') {
      cleanedCities[key] = cities[key];
    }
  }

  // Quick return if nothing is being searched for
  if (
    selectedAttributes.length === 0 &&
    selectedCountries.length === 0 &&
    selectedContinents.length === 0 &&
    (climate.weather.length === 0 || climate.months.length === 0)
  ) {
    return cleanedCities;
  }

  const selectedCountriesSet = new Set(selectedCountries);
  const selectedContinentsSet = new Set(selectedContinents);

  let resultantCities = cleanedCities;
  // Select only cities that are in the set of selected continents
  if (selectedContinentsSet.size > 0 || selectedCountriesSet.size > 0) {
    resultantCities = Object.fromEntries(
      Object.entries(cleanedCities).filter(([cityName, city]) => {
        let countryCode = city.country.toUpperCase();
        const country = countries[countryCode];
        const continent = country.continent.toLowerCase();
        countryCode = city.country.toLowerCase();
        return selectedContinentsSet.has(continent) || selectedCountriesSet.has(countryCode);
      })
    );
  }

  return filterByClimate(filterByAttributes(resultantCities, selectedAttributes), climate);
}

export const TOP_CITIES = [
  'rome',
  'santorini',
  'valencia',
  'berlin',
  'lucerne',
  'barcelona',
  'istanbul',
  'bled',
  'zurich',
  'cologne',
  'san sebastian',
  'strasbourg'
];
