const selectedContinentsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'PUT_CONTINENTS':
      return [...action.payload];
    default:
      return state;
  }
};

export default selectedContinentsReducer;
