/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTrip = /* GraphQL */ `
  mutation CreateTrip($input: CreateTripInput!, $condition: ModelTripConditionInput) {
    createTrip(input: $input, condition: $condition) {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTrip = /* GraphQL */ `
  mutation UpdateTrip($input: UpdateTripInput!, $condition: ModelTripConditionInput) {
    updateTrip(input: $input, condition: $condition) {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrip = /* GraphQL */ `
  mutation DeleteTrip($input: DeleteTripInput!, $condition: ModelTripConditionInput) {
    deleteTrip(input: $input, condition: $condition) {
      id
      name
      tripObject
      editors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTripEditor = /* GraphQL */ `
  mutation CreateTripEditor($input: CreateTripEditorInput!, $condition: ModelTripEditorConditionInput) {
    createTripEditor(input: $input, condition: $condition) {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTripEditor = /* GraphQL */ `
  mutation UpdateTripEditor($input: UpdateTripEditorInput!, $condition: ModelTripEditorConditionInput) {
    updateTripEditor(input: $input, condition: $condition) {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTripEditor = /* GraphQL */ `
  mutation DeleteTripEditor($input: DeleteTripEditorInput!, $condition: ModelTripEditorConditionInput) {
    deleteTripEditor(input: $input, condition: $condition) {
      id
      trip {
        id
        name
        tripObject
        editors {
          nextToken
        }
        createdAt
        updatedAt
      }
      editor {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: CreateFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    createFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback($input: UpdateFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    updateFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback($input: DeleteFeedbackInput!, $condition: ModelFeedbackConditionInput) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      type
      status
      description
      reporter {
        id
        username
        visited
        wantToGo
        search
        email
        emailConfirmed
        profilePicture
        trips {
          nextToken
        }
        feedbacks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
    createUser(input: $input, condition: $condition) {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      visited
      wantToGo
      search
      email
      emailConfirmed
      profilePicture
      trips {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      feedbacks {
        items {
          id
          type
          status
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
