import { updateLocalUserState, updateRemoteUserState } from './user';
import { addToSet, removeFromSet } from '../util';

export const addToWantToGo = async (dispatch, user, wantToGo, destination) => {
  wantToGo = addToSet(wantToGo, destination);
  if (user !== null) {
    user.wantToGo = wantToGo;
  }
  await updateLocalUserState(dispatch, user, null, wantToGo);
  await updateRemoteUserState(dispatch, user);
};

export const removeFromWantToGo = async (dispatch, user, wantToGo, destination) => {
  wantToGo = removeFromSet(wantToGo, destination);
  if (user !== null) {
    user.wantToGo = wantToGo;
  }
  await updateLocalUserState(dispatch, user, null, wantToGo);
  await updateRemoteUserState(dispatch, user);
};
