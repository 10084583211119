import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { onPutUser } from '../../graphql/subscriptions';
import { deSerializeUser, updateLocalUserState } from './user';

export const setupUserSubscriptionsGraph = async (dispatch, user) => {
  try {
    GraphQLAPI.graphql(graphqlOperation(onPutUser, { id: user.id })).subscribe({
      next: (sub) => {
        user = deSerializeUser(sub.value.data.onPutUser);
        updateLocalUserState(dispatch, user, user.visited, user.wantToGo, user.search);
      }
    });
  } catch (error) {
    console.error('User subscription failed', error);
  }
};
