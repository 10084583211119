import React, { useState } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { ColouredLogo, colourScheme } from '../util/colourSchemes';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import BugIcon from 'mdi-react/BugIcon';
import FeedbackIcon from 'mdi-react/FeedbackIcon';
import FeatureHighlightIcon from 'mdi-react/FeatureHighlightIcon';
import { GraphQLAPI, graphqlOperation } from '@aws-amplify/api-graphql';
import { createFeedback } from '../graphql/mutations';
import { pullRemoteUserState } from '../repository/user/user';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import Snackbar from '@material-ui/core/Snackbar';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import EmailIcon from 'mdi-react/EmailIcon';
import EmailMarkAsUnreadIcon from 'mdi-react/EmailMarkAsUnreadIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import { event } from '../util/tracking';
import Auth from '@aws-amplify/auth';
import GoogleButton from 'react-google-button';

const handleLogin = async (provider) => {
  event(
    {
      category: 'Authentication',
      action: 'Clicked Feature Login',
      label: 'Google'
    },
    'login',
    { method: 'Google' }
  );
  await Auth.federatedSignIn({ provider });
};

function Feedback(props) {
  const { updateFeedbackAction, user, globalTheme } = props;

  let reportText = '';
  const [feedbackType, setFeedbackType] = useState('bug');
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [snackBarText, setSnackBarText] = useState('');
  const [preventDoubleClick, setPreventDoubleClick] = useState(false);
  if (feedbackType === 'bug') {
    reportText = 'Report a BUG';
  }
  if (feedbackType === 'feedback') {
    reportText = 'Give us FEEDBACK on how you use Travel-Atlas';
  }
  if (feedbackType === 'feature request') {
    reportText = 'Tell us what NEW FEATURES you want to see';
  }

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://travel-atlas.com/give-feedback" />
        <meta charSet="utf-8" />
        <title>Feedback | Travel-Atlas</title>
        <meta name="description" content={`Reports bugs, give feedback, and request features for Travel-Atlas`} />
      </Helmet>
      {user === null ? (
        <Container style={{ marginTop: '32px' }}>
          <Paper
            style={{
              backgroundColor: colourScheme[globalTheme.colourScheme].cardColour,
              background: colourScheme[globalTheme.colourScheme].cardColour
            }}
          >
            <Grid container justify="center" alignItems="center" direction={'column'}>
              <ColouredLogo size={'320px'} theme={globalTheme} alt="travel-atlas logo" />
              <GoogleButton
                style={{
                  boxShadow:
                    '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 4px 7px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                }}
                type={globalTheme.colourScheme === 'dark' ? 'dark' : 'light'}
                onClick={() => handleLogin('Google')}
              />
              <Typography
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  color: colourScheme[globalTheme.colourScheme].primaryText,
                  marginBottom: '24px'
                }}
              >
                You can only use this feature while logged-in.
              </Typography>
            </Grid>
          </Paper>
        </Container>
      ) : (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            open={snackBarText !== ''}
            autoHideDuration={5000}
            onClose={() => setSnackBarText('')}
            message={snackBarText}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackBarText('')}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <Container style={{ margin: 'auto', marginTop: '32px' }}>
            <Paper
              style={{
                margin: '6px',
                padding: '12px',
                background: colourScheme[globalTheme.colourScheme].cardColour,
                backgroundColor: colourScheme[globalTheme.colourScheme].cardColour
              }}
            >
              <Grid spacing={3} container justify="center" alignItems="center" direction="column">
                <Grid item>
                  <Typography variant={'h3'} style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}>
                    Feedback
                  </Typography>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={feedbackType}
                    exclusive={true}
                    size={'large'}
                    onChange={(event, value) => {
                      setFeedbackType(value);
                    }}
                    aria-label="Feedback Type"
                  >
                    <ToggleButton value="bug" aria-label="filter">
                      <BugIcon
                        style={{
                          color: '#ff0033'
                        }}
                      />
                    </ToggleButton>
                    <ToggleButton value="feedback" aria-label="feedback">
                      <FeedbackIcon
                        style={{
                          color: '#006994'
                        }}
                      />
                    </ToggleButton>
                    <ToggleButton value="feature request" aria-label="feature request">
                      <FeatureHighlightIcon
                        style={{
                          color: '#239B56'
                        }}
                      />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Typography
                  variant={'h6'}
                  style={{
                    color: colourScheme[globalTheme.colourScheme].primaryText,
                    textAlign: 'center',
                    marginBottom: '12px'
                  }}
                >
                  {reportText}
                </Typography>
                <TextField
                  value={feedbackDescription}
                  onChange={(event) => {
                    setFeedbackDescription(event.target.value);
                  }}
                  multiline
                  rows={12}
                  fullWidth={true}
                  variant="outlined"
                  style={{ padding: '12px' }}
                />
                <Button
                  color="primary"
                  variant={'contained'}
                  disabled={preventDoubleClick}
                  disableElevation
                  style={{ marginTop: '12px', marginBottom: '12px' }}
                  onClick={async () => {
                    setPreventDoubleClick(true);
                    if (!feedbackType) {
                      setSnackBarText('Select a type of feedback and submit again.');
                      return;
                    }
                    if (feedbackDescription === '') {
                      setSnackBarText('Type a description for the feedback in the text area.');
                      return;
                    }
                    await updateFeedbackAction(user, feedbackType, feedbackDescription);
                    setFeedbackDescription('');
                    setPreventDoubleClick(false);
                  }}
                >
                  Send Feedback
                </Button>
              </Grid>
            </Paper>
            <Grid container justify={'center'} alignItems={'center'} style={{ margin: 'auto' }}>
              {user.feedbacks.items.map((feedback) => (
                <Grid item key={feedback.id}>
                  <Card
                    style={{
                      background: colourScheme[globalTheme.colourScheme].cardColour,
                      textAlign: 'center',
                      margin: '12px'
                    }}
                  >
                    <CardActionArea style={{ padding: '12px' }}>
                      <CardMedia>
                        {feedback.type === 'bug' ? <BugIcon style={{ color: '#ff0033' }} /> : null}
                        {feedback.type === 'feedback' ? <FeedbackIcon style={{ color: '#006994' }} /> : null}
                        {feedback.type === 'feature request' ? (
                          <FeatureHighlightIcon style={{ color: '#239B56' }} />
                        ) : null}
                        {feedback.status === 'unread' ? (
                          <EmailIcon style={{ color: colourScheme[globalTheme.colourScheme].primaryText }} />
                        ) : null}
                        {feedback.status === 'read' ? (
                          <EmailMarkAsUnreadIcon
                            style={{ color: colourScheme[globalTheme.colourScheme].primaryText }}
                          />
                        ) : null}
                        {feedback.status === 'implemented' ? (
                          <CheckIcon style={{ color: colourScheme[globalTheme.colourScheme].primaryText }} />
                        ) : null}
                      </CardMedia>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="body1"
                          style={{
                            color: colourScheme[globalTheme.colourScheme].primaryText
                          }}
                        >
                          {feedback.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  globalTheme: state.theme,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  updateFeedbackAction: async (user, type, description) => {
    const input = {
      type,
      description,
      feedbackReporterId: user.id,
      status: 'unread'
    };
    await GraphQLAPI.graphql(graphqlOperation(createFeedback, { input }));
    await pullRemoteUserState(dispatch, user, 'Feedback');
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
